import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchUrl } from '../actions/helpers';
import { getUsersWithParams, getUsers } from '../actions/api';
import { setUsersList, setALLPaginationParams, setPageNumber, clearUsersList } from '../actions/localStates';

import UserSearchView from '../components/UserSearchView'

class UserSearchContainer extends Component {
	constructor(props) {
    super(props);

        this.state = {
        }

        this.userSearchRef = React.createRef();

        this.searchUser = this.searchUser.bind(this);
        this.getUsers = this.getUsers.bind(this);
    }

	componentDidMount() {

	}

	getUsers() {
		this.props.actions.getUsers(this.props.base.serviceUrl, this.props.login.accessToken)
		.then((response) => {
			if (response === null) {
				this.props.actions.setIsLoggedIn(false);
				return
			}
			this.props.actions.clearUsersList()
			this.props.actions.setUsersList(response.content)
			this.props.actions.setALLPaginationParams(response.page)
		})
	}

	searchUser() {
		var params = {
			url: this.props.base.serviceUrl,
			type: "user",
			size: this.props.page.size,
			userName: this.props.search.userId || "",
			firstName: this.props.search.userName || "",
			secondName: this.props.search.userSurname || "",
			status: this.props.search.userStatus || ""
		}
		console.log(this.props.actions.searchUrl(params))
		this.props.actions.getUsersWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
		.then((response) => {
			console.log(response);
			this.props.actions.clearUsersList()
			this.props.actions.setUsersList(response.content)
			this.props.actions.setALLPaginationParams(response.page)
		})
	}

	render() {
		return (
			<div>
				<UserSearchView searchUser={this.searchUser} refresh={this.getUsers} forwardRef={this.userSearchRef} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        search: state.userSearchReducer,
        login: state.loginReducer,
        page: state.userPaginationReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    searchUrl,
	    getUsersWithParams,
	    setUsersList,
	    setALLPaginationParams,
	    getUsers,
	    setPageNumber,
	    clearUsersList
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchContainer);