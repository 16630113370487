import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { changeDrawerState, changeSelectedTab, clearSelectedUser, clearSelectedClient, clearTokensList, clearAllUserFields, clearAllClientsFields,
			setShowCreateOrEditForm, setShowCreateOrEditUserForm, setSelectedItem, setShowTokens, setShowClientTokensView, setShowUserTokensView } from '../actions/localStates'

class Drawer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	drawerOpen: false
	    }

	    this.handleDrawerClick = this.handleDrawerClick.bind(this)
	}

	componentDidMount() {
		this.setState({drawerOpen: false})
		this.props.actions.changeDrawerState(false);
	}

	handleDrawerClick () {
		this.setState({drawerOpen: !this.state.drawerOpen})
		this.props.actions.changeDrawerState(!this.state.drawerOpen);
	}

	handleSelectedTabChanged (value) {
		this.props.actions.changeSelectedTab(value);
		this.props.actions.clearSelectedUser();
		this.props.actions.clearSelectedClient();
		this.props.actions.clearTokensList();
		this.props.actions.clearAllUserFields();
		this.props.actions.clearAllClientsFields();
		this.props.actions.setShowCreateOrEditForm(false);
		this.props.actions.setShowCreateOrEditUserForm(false);
		this.props.actions.setShowClientTokensView(false);
		this.props.actions.setShowUserTokensView(false);
		this.props.actions.setSelectedItem(null);
		this.props.actions.setShowTokens(false);
	}

	render() {
		let drawerClasses = 'drawer'
		if (this.state.drawerOpen) {
		    drawerClasses = 'drawer open'
		}
		return (
			<nav className={drawerClasses}>
		      	<ul style={{"paddingLeft": "10px"}}>
		      		<drawer-li style={{margin: "0px", height: "48px"}}>
			          <div style={{"cursor": "pointer"}}>
				          <span style={{float: "right", marginRight: "18px", marginTop: "12px"}} onClick={this.handleDrawerClick}>
				          {
				          	this.state.drawerOpen
				          	? <i className="fa fa-chevron-left"></i>
				          	: <i className="fa fa-chevron-right"></i>
				          }
				          </span>
			          </div>
			        </drawer-li>
			      	<li style={{marginTop: "0px"}}>
			            <div style={{"cursor": "pointer"}} onClick={this.handleSelectedTabChanged.bind(this, 0)} value={0}>
				          	{
				          		this.state.drawerOpen
				          		? <span><i className="fa fa-users"></i>&nbsp;{i18next.t("usersTitle")}</span>
				          		: <span style={{float: "right", marginRight: "18px"}}><i className="fa fa-users"></i></span>
				          	}
			            </div>
			        </li>
			        <li>
			            <div style={{"cursor": "pointer"}} onClick={this.handleSelectedTabChanged.bind(this, 1)} value={1}>
				            {
				          		this.state.drawerOpen
				          		? <span><i className="fa fa-cubes"></i>&nbsp;{i18next.t("clientsTitle")}</span>
				          		: <span style={{float: "right", marginRight: "18px"}}><i className="fa fa-cubes"></i></span>
				          	}
			        	</div>
			        </li>
			        <li>
			            <div style={{"cursor": "pointer"}} onClick={this.handleSelectedTabChanged.bind(this, 2)} value={2}>
				            {
				          		this.state.drawerOpen
				          		? <span><i className="fa fa-tags"></i>&nbsp;{i18next.t("tokensTitle")}</span>
				          		: <span style={{float: "right", marginRight: "18px"}}><i className="fa fa-tags"></i></span>
				          	}
			          	</div>
			        </li>
			        <li>
			            <div style={{"cursor": "pointer"}} onClick={this.handleSelectedTabChanged.bind(this, 3)} value={3}>
			            	{
				          		this.state.drawerOpen
				          		? <span><i className="fa fa-sliders"></i>&nbsp;{i18next.t("configTitle")}</span>
				          		: <span style={{float: "right", marginRight: "18px"}}><i className="fa fa-sliders"></i></span>
				          	}
				        </div>
			        </li>
		      	</ul>
		    </nav>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    changeDrawerState,
	    changeSelectedTab, clearSelectedUser,
	    clearSelectedClient, clearTokensList,
	    clearAllUserFields,
		clearAllClientsFields,
		setShowCreateOrEditForm,
		setShowCreateOrEditUserForm,
		setSelectedItem,
		setShowTokens, setShowClientTokensView,
		setShowUserTokensView
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);