import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next';

import { setSelectedUser, setAllUserFields, setShowCreateOrEditUserForm, clearSelectedUser, setCreateUserMode, clearAllUserFields, setUsersList,
			changeSearchState, setPageSize, setALLPaginationParams, setIsLoggedIn, setUserPaginationParams, clearUsersList } from '../actions/localStates'

import { getUser, blockUser, getUsers, unblockUser, revokeUser, getUsersWithParams } from '../actions/api';

import { pageUrl, formatDate, searchUrl } from '../actions/helpers';

import UserSearchContainer from '../containers/UserSearchContainer'
import ConfirmPopup from './ConfirmPopup'

import first from '../img/first.svg'
import last from '../img/last.svg'

let searchBar;

class UsersListView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	statusValues: ["ACTIVE", "BLOCKED", "REVOKED"],
	    	searchOpen: false,
	    	showSearchForm: false,
	    	elementsCountArray: [10, 20, 50],
	    	showItemsCountForm: false,
	    	current_page: 1,
	    	current_user: null,
	    	current_event: null,
	    	showPopup: false,
	    	records_per_page: this.props.page.size
	    }

	    this.handleShowCreateForm = this.handleShowCreateForm.bind(this);
	    this.handleSearchClick = this.handleSearchClick.bind(this);
	    this.handleShowCountForm = this.handleShowCountForm.bind(this);
	    this.handleShowAlertMessage = this.handleShowAlertMessage.bind(this);
	    this.changeStatusWithParams = this.changeStatusWithParams.bind(this)

	    this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.firstPage = this.firstPage.bind(this);
		this.lastPage = this.lastPage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.numPages = this.numPages.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
	}

	handleShowAlertMessage () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });

	    var params = {
			url: this.props.base.serviceUrl,
			type: "user",
			page: this.props.page.number,
			size: this.props.page.size,
			userName: this.props.search.userId || "",
			firstName: this.props.search.userName || "",
			secondName: this.props.search.userSurname || "",
			status: this.props.search.userStatus || ""
		}

		this.props.actions.getUsersWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
		.then((response) => {
			if (response === null) {
				this.props.actions.setIsLoggedIn(false);
				return
			}
			this.props.actions.clearUsersList()
			this.props.actions.setUsersList(response.content)
			this.props.actions.setALLPaginationParams(response.page)
		})
	}

	prevPage ()	{
		if (this.props.page.number > 0) {
	        this.changePage(this.props.page.number - 1);
	    }
	}

	firstPage ()	{
	    this.changePage(0);
	}

	lastPage ()	{
	    this.changePage(this.numPages() - 1);
	}

	nextPage () {
		if (this.props.page.number < this.numPages()) {
	        this.changePage(this.props.page.number + 1);
	    }
	}

	changePage (page) {

		if (this.state.showItemsCountForm === true) {
			this.setState({showItemsCountForm: false})
		}

	    if (page < 0) page = 0;
	    if (page >= this.numPages()) page = this.numPages() - 1;

	    var params = {
			url: this.props.base.serviceUrl,
			type: "user",
			page: page,
			size: this.props.page.size,
			userName: this.props.search.userId || "",
			firstName: this.props.search.userName || "",
			secondName: this.props.search.userSurname || "",
			status: this.props.search.userStatus || ""
		}

		this.props.actions.getUsersWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
		.then((response) => {
			this.props.actions.setUsersList(response.content)
			this.props.actions.setALLPaginationParams(response.page)
		})
	}

	numPages () {
	    return Math.ceil(this.props.page.totalElements / this.props.page.size);
	}

	componentDidMount () {
		var _this = this;
		this.setState({searchOpen: false})
		this.props.actions.changeSearchState(false);
		searchBar = document.getElementById("search-bar");
		searchBar.addEventListener( 'webkitTransitionEnd', function( event ) { _this.setState({showSearchForm: _this.state.searchOpen}) }, false );

		if (this.props.page.userPaginationParamsSetted) {

		    this.setState({current_page: this.props.page.number});

		    var params = {
				url: this.props.base.serviceUrl,
				type: "user",
				page: this.props.page.number,
				size: this.props.page.size,
				userName: this.props.search.userId || "",
				firstName: this.props.search.userName || "",
				secondName: this.props.search.userSurname || "",
				status: this.props.search.userStatus || ""
			}

			this.props.actions.getUsersWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
			.then((response) => {
				if (response === null) {
					this.props.actions.setIsLoggedIn(false);
					return
				}
				this.props.actions.setUsersList(response.content)
				this.props.actions.setALLPaginationParams(response.page)
			})
		} else {
			this.props.actions.getUsers(this.props.base.serviceUrl, this.props.login.accessToken)
			.then((response) => {
				if (response === null) {
					this.props.actions.setIsLoggedIn(false);
					return
				}
				this.props.actions.setUsersList(response.content)
				this.props.actions.setALLPaginationParams(response.page)
				this.props.actions.setUserPaginationParams(true)
				console.log(response);
			})
		}
	}

	handleSearchClick () {
		this.setState({searchOpen: !this.state.searchOpen})
		this.props.actions.changeSearchState(!this.state.searchOpen);
		this.setState({showSearchForm: false})
	}

	handleShowCountForm () {
		this.setState({showItemsCountForm: !this.state.showItemsCountForm})
	}

	handleCountChanged (value) {
		var page
		if (this.props.page.number < 1) page = 1;
	    if (this.props.page.number > this.numPages()) page = this.numPages();
		var params = {
			url: this.props.base.serviceUrl,
			type: "user",
			page: page - 1,
			size: value
		}
		this.props.actions.setPageSize(value);
		this.setState({showItemsCountForm: !this.state.showItemsCountForm});
		console.log(this.props.actions.pageUrl(params));

		this.props.actions.getUsersWithParams(this.props.actions.pageUrl(params), this.props.login.accessToken)
		.then((response) => {
			this.props.actions.setUsersList(response.content)
			this.props.actions.setALLPaginationParams(response.page)
			this.props.actions.setPageSize(response.page.size)
			this.numPages();
			this.changePage(this.props.page.number);
		})
	}

	formatDate(dateInString) {
        return this.props.actions.formatDate(dateInString);
    }

    getSelectedUser (name) {
		this.props.getUser(name)
	}

	changeStatus(user, value) {
		var currentAction;

		switch (value)
		{
		    case "ACTIVE":
		        currentAction = this.props.actions.unblockUser(this.props.base.serviceUrl, user.userName, this.props.login.accessToken)
		        break;

		    case "BLOCKED":
		        currentAction = this.props.actions.blockUser(this.props.base.serviceUrl, user.userName, this.props.login.accessToken)
		        break;

		    case "REVOKED":
		        currentAction = this.props.actions.revokeUser(this.props.base.serviceUrl, user.userName, this.props.login.accessToken)
		        break;

		    default:
		        currentAction = this.props.actions.unblockUser(this.props.base.serviceUrl, user.userName, this.props.login.accessToken)
		        break;
		}

		currentAction
		.then(() => {
			var params = {
				url: this.props.base.serviceUrl,
				type: "user",
				page: this.props.page.number,
				size: this.props.page.size,
				userName: this.props.search.userId || "",
				firstName: this.props.search.userName || "",
				secondName: this.props.search.userSurname || "",
				status: this.props.search.userStatus || ""
			}

			this.props.actions.getUsersWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
			.then((response) => {
				if (response === null) {
					this.props.actions.setIsLoggedIn(false);
					return
				}
				this.props.actions.clearUsersList()
				this.props.actions.setUsersList(response.content)
				this.props.actions.setALLPaginationParams(response.page)
				this.setState({"current_user": null})
				this.setState({"current_event": null})
			})

			if (this.state.showPopup) {
				this.setState({"showPopup": false})
			}
		})
	}

	onStatusChanged (user, e) {
		if (e.target.value === "REVOKED") {
			this.setState({"current_user": user})
			this.setState({"current_event": e.target.value})
			this.handleShowAlertMessage()
		} else {
			this.changeStatus(user, e.target.value)
		}
	}

	changeStatusWithParams () {
		this.changeStatus(this.state.current_user, this.state.current_event)
	}

	handleShowCreateForm () {
    	this.props.actions.setShowCreateOrEditUserForm(true)
    	this.props.actions.setCreateUserMode(true)
    	this.props.actions.clearAllUserFields()
    }

	render() {
		let searchBarOpenState = 'searh-bar'
		let itemsCountFormOpen = 'dropdown-menu'
		let useIEStyle = 'offset-8 bottomright'

		if (this.props.base.isIEBrowser) {
			useIEStyle = 'offset-8 bottomright-ie'
		}

		if (this.props.base.searchIsOpen) {
		    searchBarOpenState = 'searh-bar open'
		}

		if (this.state.showItemsCountForm) {
			itemsCountFormOpen = 'dropdown-menu show'
		}
		return (
			<div>
				<div style={{paddingBottom: "50px"}}>
					<button className="btn btn-outline-info" style={{float: "right", marginRight: "15px"}} onClick={this.handleShowCreateForm}>{i18next.t("addUserTitle")}</button>
				</div>
				<div id="search-bar" className={searchBarOpenState}>
					<div style={{"cursor": "pointer", marginTop: "-5px"}}>
						<div style={{float: "right", marginRight: "15px"}} onClick={this.handleSearchClick}>
							{
								this.state.searchOpen
								? <i className="fa fa-chevron-up"></i>
								: <i className="fa fa-chevron-down"></i>
							}
						</div>
					</div>
					<div style={{paddingTop: "20px"}}>
						{
							this.state.showSearchForm
							? <UserSearchContainer />
							: null
						}
					</div>
				</div>
				<div className="table-holder">
					<div className="col-12 table_div" style={{paddingLeft: "0px", paddingRight: "0px", height: "100%"}}>
						<div className="row fixed_header_div">
							<div className="col-2">{i18next.t("uniqueIdLabel")}</div>
							<div className="col-2">{i18next.t("userStatusLabel")}</div>
							<div className="col-2">{i18next.t("userNameSurnameLabel")}</div>
							<div className="col-2">{i18next.t("departmentLabel")}</div>
							<div className="col-2">{i18next.t("positionLabel")}</div>
							<div className="col-2">{i18next.t("createdDateLabel")}</div>
						</div>
						<div className="fixed_div">
							{
		            			this.props.users.usersList.map((user, index) => {
								    return (<div key={index} className="row" style={{marginTop: "5px"}}>
								    		<div className="col-2" style={{textAlign: "left", cursor: "pointer"}} onClick={this.getSelectedUser.bind(this, user)}><i className="fa fa-user-circle"></i>&nbsp;{user.userName}</div>
								    		<div className="col-2">
								    			{
								    				user.status === "REVOKED"
								    				? <div className="col-12 status-placeholder" disabled>
								    					{i18next.t("REVOKED")}
								    				</div>
								    				: <select type="select" className="form-control" defaultValue={user.status} style={{textAlign: "center", textAlignLast: "center"}} onChange={this.onStatusChanged.bind(this, user)}>
						                                {
						                                  this.state.statusValues.map((value, index) =>
						                                    <option key={index} value={value}>{i18next.t(value)}</option>)
						                                }          
						                            </select>
								    			}
				                            </div>
				                            <div className="col-2" style={{textAlign: "center"}}>{user.secondName} {user.firstName}</div>
								    		<div className="col-2" style={{textAlign: "center"}}>{user.department}</div>
								    		<div className="col-2" style={{textAlign: "center"}}>{user.position}</div>
								    		<div className="col-2" style={{textAlign: "center"}}>{this.formatDate(user.createdDate)}</div>
								    </div>)
								})
		            		}
		            	</div>
					</div>
				</div>

				{
					this.numPages() === 0
					? null
					: <div className={useIEStyle}>
						<div className="row">
							<div className="btn-group dropup" style={{minWidth: "5rem"}}>
								<button type="button" onClick={this.handleShowCountForm} className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showItemsCountForm}>
									{
										this.props.page.size
									}
								</button>
								<div className={itemsCountFormOpen} style={{minWidth: "5rem"}}>
									{
										this.state.elementsCountArray.map((value, index) => {
											return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleCountChanged.bind(this, value)}>{value}</span>)
										})
									}
								</div>
							</div>

							<div className="row" style={{width: "255px", textAlign: "center"}}>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.firstPage}>
									<img className="img" style={{marginTop: "5px"}} src={first} width="20" height="26" alt={first}/>
								</div>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "5px"}} onClick={this.prevPage}>
									<i className="fa fa-chevron-left"></i>
								</div>
								<div className="col-5" style={{paddingTop: "5px", textAlign: "center", paddingLeft: "0px", paddingRight: "0px"}}>
									{
										this.props.page.number + 1
									}
									<span style={{paddingTop: "5px"}}> / </span>
									{
										this.numPages()
									}
								</div>
								
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "5px", paddingLeft: "0px"}} onClick={this.nextPage}>
									<i className="fa fa-chevron-right"></i>
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingLeft: "0px"}} onClick={this.lastPage}>
									<img className="img" style={{marginTop: "5px"}} src={last} width="20" height="26" alt={last}/>
								</div>
							</div>

							<div>
								{
									this.state.showPopup
									? <ConfirmPopup confirmChanges={this.changeStatusWithParams} closePopup={this.handleShowAlertMessage.bind(this)} />
						            : null
						        }
					        </div>
						</div>
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        users: state.usersReducer,
        user: state.userReducer,
        page: state.userPaginationReducer,
        login: state.loginReducer,
        search: state.userSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getUser,
	    setSelectedUser,
	    setAllUserFields,
	    setShowCreateOrEditUserForm,
	    clearSelectedUser,
	    setCreateUserMode,
	    clearAllUserFields,
	    blockUser,
	    getUsers, setUsersList,
	    unblockUser, revokeUser,
	    changeSearchState,
	    setPageSize,
	    pageUrl, setALLPaginationParams,
	    setIsLoggedIn,
	    getUsersWithParams,
	    formatDate,
	    setUserPaginationParams,
	    searchUrl,
	    clearUsersList
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersListView);