import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Cookies from 'universal-cookie';

import { clearLoggedInUserData, clearToken, setIsLoggedIn, setSelectedUser, setAllUserFields, setSelectedItem, setCreateUserMode, setShowCreateOrEditUserForm,
			changeSelectedTab, clearTokensList, setShowUserTokensView, setShowTokens, clearSelectedUser, clearSelectedClient, clearAllUserFields,
			clearAllClientsFields, setShowCreateOrEditForm, setShowClientTokensView, clearTokenForSearch, clearResultList, setLoginInput, setPasswordInput,
			clearPhoneParams, setPhoneParams, clearTwoFactorEnabled, clearTwoFactorCode, clearAllUserFormParams, setShowSessionEndHandler,
			setNeedToLogout, setNeedReLogin } from '../actions/localStates'
import { getUsers, getUser } from '../actions/api'

import AboutPopup from './AboutPopup'
import i18next from 'i18next'

const cookies = new Cookies();

class Header extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	showOptions: false,
	    	showPopup: false,
	    	showApps: false
	    }

	    this.handleShowOptions = this.handleShowOptions.bind(this);
	    this.closeMenu = this.closeMenu.bind(this);
	    this.logoutHandler = this.logoutHandler.bind(this);
	    this.getCurrentUser = this.getCurrentUser.bind(this);
	    this.showVersion = this.showVersion.bind(this);

	    this.handleShowApps = this.handleShowApps.bind(this);
	    this.closeApps = this.closeApps.bind(this);
	}

	handleShowOptions (event) {
		event.preventDefault();
		this.setState({
				showOptions: !this.state.showOptions
			}, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	logoutHandler () {
		this.props.actions.clearLoggedInUserData();
		this.props.actions.clearToken();
		this.props.actions.setIsLoggedIn(false);
		this.props.actions.clearSelectedUser();
		this.props.actions.clearSelectedClient();
		this.props.actions.clearAllUserFields();
		this.props.actions.clearAllClientsFields();
		this.props.actions.clearTokensList();
		this.props.actions.setShowCreateOrEditForm(false);
		this.props.actions.setShowCreateOrEditUserForm(false);
		this.props.actions.setSelectedItem(null);
		this.props.actions.setShowTokens(false);
		this.props.actions.setShowUserTokensView(false);
		this.props.actions.setShowClientTokensView(false);
		this.props.actions.changeSelectedTab(0);
		this.props.actions.clearTokenForSearch();
		this.props.actions.clearResultList();
		this.props.actions.setLoginInput("");
		this.props.actions.setPasswordInput("");
		this.props.actions.clearTwoFactorEnabled();
		this.props.actions.clearTwoFactorCode();
		this.props.actions.clearAllUserFormParams();

		this.props.actions.setShowSessionEndHandler(false);
		this.props.actions.setNeedToLogout(false);
		this.props.actions.setNeedReLogin(false);
	}

	showVersion () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });
	}

	handleShowApps (event) {
		event.preventDefault();
		this.setState({
				showApps: !this.state.showApps
			}, () => {
			document.addEventListener('click', this.closeApps);
		});
	}

	componentDidMount() {

	}

	closePopup () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });
	}

	getCurrentUser () {
		var name = "?userName=" + this.props.login.loggedInUserId + "&status=ACTIVE";
		this.props.actions.getUser(this.props.base.serviceUrl, name, this.props.login.accessToken)
		.then((response) => {
			this.props.actions.setShowCreateOrEditUserForm(false)
			this.props.actions.clearPhoneParams()
    		if (response.content[0].phone !== undefined) {
    			var phoneParams = {
	    			index: response.content[0].phone.substr(0, 3) || "",
	    			code: response.content[0].phone.substr(3, 3) || "",
	    			number: response.content[0].phone.substr(6, response.content[0].phone.length - 1) || ""
	    		}

	    		this.props.actions.setPhoneParams(phoneParams);
    		}
			this.props.actions.clearAllUserFields();
    		this.props.actions.changeSelectedTab(0);
    		this.props.actions.setCreateUserMode(false)
    		this.props.actions.setSelectedUser(response.content[0]);
    		this.props.actions.setAllUserFields(response.content[0]);
    		this.props.actions.setSelectedItem(response.content[0].userName);
    		this.props.actions.setShowCreateOrEditUserForm(true)
    		this.props.actions.clearTokensList();
			this.props.actions.setShowUserTokensView(false);
			this.props.actions.setShowTokens(false);
		})
	}

	showMenu(event) {
		event.preventDefault();

		this.setState({ showOptions: true }, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	closeApps(event) { 
		this.setState({ showApps: false }, () => {
			document.removeEventListener('click', this.closeApps);
		});  
	}

	closeMenu(event) { 
		this.setState({ showOptions: false }, () => {
			document.removeEventListener('click', this.closeMenu);
		});  
	}

	redirectToApp(url) {
		if (this.props.login.accessToken !== null) {
			cookies.set('tempTokenData', this.props.login.accessToken, { path: '/', expires: new Date(Date.now() + 10000), domain: this.props.base.domain });
		}

		window.location.href = url;
	}

	render() {
		let showOptionsOpen = 'dropdown-menu'
		if (this.state.showOptions) {
			showOptionsOpen = 'dropdown-menu show'
		}

		let showAppsOpen = 'dropdown-menu'
		if (this.state.showApps) {
			showAppsOpen = 'dropdown-menu show'
		}

		return (
			<div style={{width: "100%", height: "60px", backgroundColor: "#1C92C7", positon: "fixed", top: "0px", left: "0px", zIndex: "10"}}>
				<div className="row col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
					{
						this.props.base.apps !== undefined && this.props.base.apps.length > 0
						? <div style={{paddingRight: "0px", paddingTop: "5px", float: "right"}}>
							<div style={{textAlign: "right",  color: "white"}}>
								<div className="btn-group">
									<button type="button" style={{color: "white"}} onClick={this.handleShowApps} className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showApps}>
										<i className="fa fa-bars fa-2x"></i>
									</button>
									<div className={showAppsOpen} style={{minWidth: "200px", width: "100%", backgroundColor: "#1C92C7"}}>
										{
											this.props.base.apps.map((item, index) => {
												return <span className="dropdown-item header-span" key={index} onClick={this.redirectToApp.bind(this, item.url)} style={{color: "white", textDecoration: "none", cursor: "pointer"}}>{item.clientTitle}</span>
											})	
										}
									</div>
								</div>
							</div>
						</div>
						: null
					}
					<span className="col-8" style={{textAlign: "left", fontSize: "24px", paddingTop: "10px", color: "white"}}>
						{i18next.t("headerTitle")}
					</span>
					<span className="col-2" style={{textAlign: "right"}}>
						
					</span>
					<div style={{paddingRight: "0px", paddingTop: "15px", float: "right"}}>
						{
							this.props.login.loggedInUserData
							? <div style={{textAlign: "right",  color: "white"}}>
								<div className="btn-group dropdown topright" style={{minWidth: "200px"}}>
									<button type="button" style={{color: "white"}} onClick={this.handleShowOptions} className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showOptions}>
										<span>{this.props.login.loggedInUserName}</span>&nbsp;
										<span style={{textAlign: "right"}}>({this.props.login.loggedInUserId})</span>
									</button>
									<div className={showOptionsOpen} style={{minWidth: "5rem", width: "100%", backgroundColor: "#1C92C7"}}>
										<span className="dropdown-item header-span" onClick={this.getCurrentUser} style={{cursor: "pointer", color: "white"}}>{i18next.t("userDataButtonText")}</span>
										<span className="dropdown-item header-span" style={{cursor: "pointer", color: "white"}} onClick={this.showVersion}>{i18next.t("aboutButtonText")}</span>
										<span className="dropdown-item header-span" onClick={this.logoutHandler} style={{cursor: "pointer", color: "white"}}>{i18next.t("exitButtonText")}</span>
									</div>
								</div>
							</div>
							: null
						}
					</div>
				</div>
				{
					this.state.showPopup
					? <AboutPopup closePopup={this.closePopup.bind(this)} />
		            : null
		        }
		        {
					this.props.base.needToLogout
					? this.logoutHandler()
					: null
				}			
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    clearLoggedInUserData, clearToken,
	    setIsLoggedIn,
	    getUsers, getUser,
	    setSelectedUser,
	    setSelectedItem, setCreateUserMode,
	    setShowCreateOrEditUserForm,
	    changeSelectedTab,
	    clearTokensList,
		setShowUserTokensView,
		setShowTokens, setAllUserFields,
		clearSelectedUser, clearSelectedClient,
		clearAllUserFields,
		clearAllClientsFields,
		setShowCreateOrEditForm,
		setShowClientTokensView,
		clearTokenForSearch, clearResultList,
		setLoginInput, setPasswordInput,
		clearPhoneParams, setPhoneParams,
		clearTwoFactorEnabled, clearTwoFactorCode,
		clearAllUserFormParams, setShowSessionEndHandler,
		setNeedToLogout, setNeedReLogin
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);