import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

class ConfirmPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
      }

  }

  componentDidMount() {
    var element = document.getElementById("cancel");
    element.focus();
  }

  render() {
      return (
          <div className='popup'>
                <div className="popup_inner_confirm">
                    <div className="col-12" style={{paddingTop: "30px", paddingLeft: "30px", fontSize: "18px", color: "grey"}}>
                        <span>{i18next.t("statusChangedConfirmationMessageFirstPart")}</span>
                        <p></p>
                        <span>{i18next.t("statusChangedConfirmationMessageSecondPart")}</span>
                        <div style={{float: "right", paddingRight: "15px", paddingTop: "15px"}}>
                          <button className="btn btn-info" style={{marginRight: "10px", width: "75px"}} onClick={this.props.confirmChanges}>Ok</button>
                          <button id="cancel" className="btn btn-outline-info" style={{marginRight: "0px", width: "75px"}} onClick={this.props.closePopup}>{i18next.t("cancelLabel")}</button>
                        </div>
                    </div>
                </div>
          </div>
      );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPopup);