const baseState = {
    usersList: []
}

const usersReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_USERS_LIST": {
			return {
				...state,
				usersList: payload
			}
		}

		case "CLEAR_USERS_LIST": {
			return {
				...state,
				usersList: []
			}
		}

        default:
            return state;
    }
}

export default usersReducer