import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setClientNameForSearch, clearClientNameForSearch, setClientStatusForSearch, clearClientStatusForSearch, setClientIdForSearch, clearClientIdForSearch } from '../actions/localStates'

import i18next from 'i18next'

class ClientSearchView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	statusValues: ["ACTIVE", "BLOCKED", "REVOKED", "ACTIVE_BLOKED"]
	    }

	    this.onClientNameChanged = this.onClientNameChanged.bind(this);
		this.onClientStatusChanged = this.onClientStatusChanged.bind(this);
		this.onClientIdChanged = this.onClientIdChanged.bind(this);
		this.cleanUp = this.cleanUp.bind(this);

		this.handleSearchKeyUp = this.keyUpHandler.bind(this, 'Search');
	}

	componentDidMount() {
		
	}

	keyUpHandler(refName, e) {
        if (e.keyCode === 13) {
    		document.getElementById("search").click();
       		document.activeElement.blur()
        }
    }

	cleanUp() {
		this.props.actions.clearClientNameForSearch()
		this.props.actions.setClientStatusForSearch("")
		this.props.actions.clearClientIdForSearch()
		this.props.refresh()
	}

	searchClient() {
		this.props.searchClient()
	}

	onClientNameChanged (e) {
		this.props.actions.setClientNameForSearch(e.target.value)
	}

	onClientStatusChanged (e) {
		if (e.target.value === "ACTIVE_BLOKED") {
			this.props.actions.setClientStatusForSearch("")
		} else {
			this.props.actions.setClientStatusForSearch(e.target.value)
		}
	}

	onClientIdChanged (e) {
		this.props.actions.setClientIdForSearch(e.target.value)
	}

	render() {
		return (
			<div className="col-12" style={{padding: "0px"}}>
				<div className="row col-12" style={{padding: "0px"}}>
					<div className="col-6">
						<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("clientNameLabel")}</label>
						<input className="form-control" type="text" value={this.props.search.clientId} onChange={this.onClientIdChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
					</div>
					<div className="col-6">
						<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("clientUniqueIdLabel")}</label>
						<input className="form-control" value={this.props.search.clientName} onChange={this.onClientNameChanged} type="text" onKeyUp={this.handleSearchKeyUp} ref="Search" />
					</div>
					<div className="col-6">
						<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("statusLabel")}</label>
						<div>
							<select type="select" className="form-control" style={{textAlign: "center", textAlignLast: "center"}} onChange={this.onClientStatusChanged}>
                                {
                                  this.state.statusValues.map((value, index) =>
                                    <option key={index} value={value} selected={this.props.search.clientStatus === "" ? true : (this.props.search.clientStatus === value) ? true : false}>{i18next.t(value)}</option>)
                                }          
                            </select>
						</div>
					</div>
				</div>
				<div style={{float: "right", marginRight: "15px", marginTop: "10px"}}>
					<button id="search" className="btn btn-info" onClick={this.searchClient.bind(this)}>{i18next.t("searchLabel")}</button>&nbsp;
					<button className="btn btn-outline-info" onClick={this.cleanUp}>{i18next.t("cleanUpLabel")}</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        search: state.clientSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setClientNameForSearch, clearClientNameForSearch,
    	setClientStatusForSearch, clearClientStatusForSearch,
    	setClientIdForSearch, clearClientIdForSearch
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientSearchView);