import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getClients, getClient } from '../actions/api';
import { setClientsList, setSelectedClient, setAllClientsFields, setShowCreateOrEditForm, setSelectedItem, setIsLoggedIn, setCreateClientMode } from '../actions/localStates'

import ClientsListView from '../components/ClientsListView';
import ClientContainer from './ClientContainer';

class ClientsContainer extends Component {

	constructor(props) {
    super(props);

	    this.state = {
		}

		this.child = React.createRef();

		this.getSelectedClient = this.getSelectedClient.bind(this);
	}

	componentDidMount() {
		this.props.actions.getClients(this.props.base.serviceUrl, this.props.login.accessToken)
		.then((response) => {
			if (response === null) {
				this.props.actions.setIsLoggedIn(false);
				return
			}
			this.props.actions.setClientsList(response.content)
			console.log(response);
		})
	}

	getSelectedClient (client) {
		var name = "?clientName=" + encodeURI(client.clientName) + "&status=" + client.status;
    	this.props.actions.getClient(this.props.base.serviceUrl, name, this.props.login.accessToken)
    	.then((response) => {
    		if (response.content.length === 0) {
    			console.log("Broken client");
    			return;
    		}

    		this.props.actions.setSelectedClient(response.content[0]);
    		this.props.actions.setAllClientsFields(response.content[0]);
    		this.props.actions.setSelectedItem(response.content[0].clientName);
    		console.log(response);
    		this.props.actions.setShowCreateOrEditForm(true)
    		this.props.actions.setCreateClientMode(false)
    	})
    }

	render() {
		return (
			<div>
				{
					this.props.base.showCreateOrEditClientForm
					? <ClientContainer />
					: <ClientsListView getClient={this.getSelectedClient} forwardRef={this.child} />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getClients,
	    getClient,
		setClientsList,
		setSelectedClient,
		setAllClientsFields,
		setShowCreateOrEditForm,
		setSelectedItem,
		setIsLoggedIn,
		setCreateClientMode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer);