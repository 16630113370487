const baseState = {
    clientsList: []
}

const clientsReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_CLIENTS_LIST": {
			return {
				...state,
				clientsList: payload
			}
		}

		case "CLEAR_CLIENTS_LIST": {
			return {
				...state,
				clientsList: []
			}
		}

        default:
            return state;
    }
}

export default clientsReducer