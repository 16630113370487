const baseState = {
    selectedClient: null,
    clientName: "",
    clientPassword: "",
    clientStatus: "",
    clientRoles: [],
    clientCreatedDate: "",
    clientActiveTokenNumber: 0,
    clientTokenExpiredAfterSec: 0,
    clientFullName: "",
    clientExtendedData: "",
    clientEmail: "",
    isClientCreation: false,
    showClientTokenView: false
}

const clientReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_SELECTED_CLIENT": {
			return {
				...state,
				selectedClient: payload
			}
		}

		case "SET_SHOW_CLIENT_TOKENS_VIEW": {
			return {
				...state,
				showClientTokenView: payload
			}
		}

		case "SET_CLIENT_CREATE_MODE": {
			return {
				...state,
				isClientCreation: payload
			}
		}

		case "SET_ALL_CLIENTS_FIELDS": {
			return {
				...state,
				clientName: payload.clientName,
			    clientStatus: payload.status,
			    clientRoles: payload.roles,
			    clientCreatedDate: payload.createdDate,
			    clientActiveTokenNumber: payload.activeTokenNumber,
			    clientTokenExpiredAfterSec: payload.tokenExpiredAfterSec,
			    clientFullName: payload.fullName,
			    clientPassword: payload.password,
			    clientEmail: payload.email,
			    clientExtendedData: payload.extendedData
			}
		}

		case "CLEAR_ALL_CLIENTS_FIELDS": {
			return {
				...state,
				clientName: "",
			    clientPassword: "",
			    clientStatus: "",
			    clientRoles: [],
			    clientCreatedDate: "",
			    clientActiveTokenNumber: 0,
			    clientTokenExpiredAfterSec: 0,
			    clientFullName: "",
			    clientExtendedData: "",
			    clientEmail: ""
			}
		}

		case "CLEAR_SELECTED_CLIENT": {
			return {
				...state,
				selectedClient: null
			}
		}

		case "SET_CLIENT_NAME": {
			return {
				...state,
				clientName: payload
			}
		}

		case "SET_CLIENT_FULL_NAME": {
			return {
				...state,
				clientFullName: payload
			}
		}

		case "SET_CLIENT_STATUS": {
			return {
				...state,
				clientStatus: payload
			}
		}

		case "SET_CLIENT_ROLE": {
			return {
				...state,
				clientRole: payload
			}
		}

		case "SET_CLIENT_ACTIVE_TOKEN_NUMBER": {
			return {
				...state,
				clientActiveTokenNumber: payload
			}
		}

		case "SET_CLIENT_TOKEN_EXPIRED_AFTER_SEC": {
			return {
				...state,
				clientTokenExpiredAfterSec: payload
			}
		}

		case "SET_CLIENT_EXTENDED_DATA": {
			return {
				...state,
				clientExtendedData: payload
			}
		}

		case "SET_CLIENT_CREATED_DATE": {
			return {
				...state,
				clientCreatedDate: payload
			}
		}

		case "SET_CLIENT_PASSWORD": {
			return {
				...state,
				clientPassword: payload
			}
		}

		case "SET_CLIENT_EMAIL": {
			return {
				...state,
				clientEmail: payload
			}
		}

        default:
            return state;
    }
}

export default clientReducer