const baseState = {
    userName: "",
    userSurname: "",
    userStatus: "",
    userId: ""
}

const userSearchReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_USER_NAME_FOR_SEARCH": {
			return {
				...state,
				userName: payload
			}
		}

		case "CLEAR_USER_NAME_FOR_SEARCH": {
			return {
				...state,
				userName: ""
			}
		}

		case "SET_USER_SURNAME_FOR_SEARCH": {
			return {
				...state,
				userSurname: payload
			}
		}

		case "CLEAR_USER_SURNAME_FOR_SEARCH": {
			return {
				...state,
				userSurname: ""
			}
		}

		case "SET_USER_STATUS_FOR_SEARCH": {
			return {
				...state,
				userStatus: payload
			}
		}

		case "CLEAR_USER_STATUS_FOR_SEARCH": {
			return {
				...state,
				userStatus: ""
			}
		}

		case "SET_USER_ID_FOR_SEARCH": {
			return {
				...state,
				userId: payload
			}
		}

		case "CLEAR_USER_ID_FOR_SEARCH": {
			return {
				...state,
				userId: ""
			}
		}

        default:
            return state;
    }
}

export default userSearchReducer