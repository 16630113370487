import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { getTokenById, blockToken, unblockToken, revokeToken, getUser, getClient } from '../actions/api';
import { setSelectedUser, setAllUserFields, setSelectedItem, changeSelectedTab, setCreateUserMode, setShowCreateOrEditUserForm,
			clearTokensList, setShowUserTokensView, setShowTokens, setSelectedClient, setAllClientsFields, setShowClientTokensView,
			setCreateClientMode, setShowCreateOrEditForm, setResultList, clearResultList, setTokenForSearch, clearTokenForSearch } from '../actions/localStates';
import { formatDate } from '../actions/helpers';

import ConfirmPopup from './ConfirmPopup'


class TokensSearchView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	searchResults: [],
	    	statusValues: ["ACTIVE", "BLOCKED", "REVOKED"],
	    	current_token: null,
	    	current_event: null,
	    	showPopup: false,
	    	input: ""
	    }

	    this.handleSearch = this.handleSearch.bind(this);
	    this.onInputChanged = this.onInputChanged.bind(this);
	    this.handleCleanUp = this.handleCleanUp.bind(this);

	    this.handleShowAlertMessage = this.handleShowAlertMessage.bind(this);
	    this.changeStatusWithParams = this.changeStatusWithParams.bind(this);
		this.changeStatus = this.changeStatus.bind(this);

		this.handleSearchKeyUp = this.keyUpHandler.bind(this, 'Search');
	}

	componentDidMount() {
		
	}

	keyUpHandler(refName, e) {
        if (e.keyCode === 13) {
    		document.getElementById("search").click();
       		document.activeElement.blur()
        }
    }

	handleShowAlertMessage () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });

	    this.handleSearch()
	}

	handleCleanUp () {
		this.props.actions.clearTokenForSearch();
		this.props.actions.clearResultList();
	}

	onStatusChanged (token, e) {
		if (e.target.value === "REVOKED") {
			this.setState({"current_token": token})
			this.setState({"current_event": e.target.value})
			this.handleShowAlertMessage()
		} else {
			this.changeStatus(token, e.target.value)
		}
	}

	changeStatusWithParams () {
		this.changeStatus(this.state.current_token, this.state.current_event)
	}

	getTokenOwner (id) {
		var selectedTab = 0, currentAction, name;
		name =  this.props.search.resultList[id].subjectType === "USER"
		? "?userName=" + this.props.search.resultList[id].subject + "&status=" + this.props.search.resultList[id].status
		: "?clientName=" + this.props.search.resultList[id].subject + "&status=" + this.props.search.resultList[id].status
		switch (this.props.search.resultList[id].subjectType)
		{
		    case "USER":
		        selectedTab = 0;
		        currentAction = this.props.actions.getUser(this.props.base.serviceUrl, name, this.props.login.accessToken)
		        break;

		    case "CLIENT":
		        selectedTab = 1;
		        currentAction = this.props.actions.getClient(this.props.base.serviceUrl, name, this.props.login.accessToken)
		        break;

		    default:
		    	selectedTab = 0;
		        currentAction = this.props.actions.getUser(this.props.base.serviceUrl, name, this.props.login.accessToken)
		        break;
		}

		
		currentAction
		.then((response) => {
			if (selectedTab === 0) {
				this.props.actions.setSelectedUser(response.content[0]);
	    		this.props.actions.setAllUserFields(response.content[0]);
	    		this.props.actions.setSelectedItem(response.content[0].userName);
	    		this.props.actions.changeSelectedTab(selectedTab);
	    		this.props.actions.setCreateUserMode(false)
	    		this.props.actions.setShowCreateOrEditUserForm(true)
	    		this.props.actions.clearTokensList();
				this.props.actions.setShowUserTokensView(false);
				this.props.actions.setShowTokens(false);
			} else {
				this.props.actions.setSelectedClient(response.content[0]);
	    		this.props.actions.setAllClientsFields(response.content[0]);
	    		this.props.actions.setSelectedItem(response.content[0].clientName);
	    		this.props.actions.changeSelectedTab(selectedTab);
	    		this.props.actions.setCreateClientMode(false)
	    		this.props.actions.setShowCreateOrEditForm(true)
	    		this.props.actions.clearTokensList();
				this.props.actions.setShowClientTokensView(false);
				this.props.actions.setShowTokens(false);
			}
			
		})
	}

	handleSearch () {
		var tempArr = [];
		this.props.actions.getTokenById(this.props.base.serviceUrl, this.props.search.token, this.props.login.accessToken)
		.then((response) => {
			if (response.code !== undefined) {
                alert(response.message);
                return
            }
            this.props.actions.clearResultList()
            tempArr.push(response);
            this.props.actions.setResultList([response]);
            console.log([response]);
		})

		if (this.state.showPopup) {
			this.setState({"showPopup": false})
		}
	}

	onInputChanged (e) {
		this.props.actions.setTokenForSearch(e.target.value)
	}

	formatDate(dateInString) {
        return this.props.actions.formatDate(dateInString);
    }

    changeStatus (token, value) {
		var currentAction;

		switch (value)
		{
		    case "ACTIVE":
		        currentAction = this.props.actions.unblockToken(this.props.base.serviceUrl, token.id, this.props.login.accessToken)
		        break;

		    case "BLOCKED":
		        currentAction = this.props.actions.blockToken(this.props.base.serviceUrl, token.id, this.props.login.accessToken)
		        break;

		    case "REVOKED":
		        currentAction = this.props.actions.revokeToken(this.props.base.serviceUrl, token.id, this.props.login.accessToken)
		        break;

		    default:
		        currentAction = this.props.actions.unblockToken(this.props.base.serviceUrl, token.id, this.props.login.accessToken)
		        break;
		}

		currentAction
		.then(() => {
			this.handleSearch()
		})
	}

    getBlobData(id) {
    	var text = this.props.search.resultList[id].body;
	    
    	var userNumber = this.props.search.resultList[id].id.substr(this.props.search.resultList[id].id.length - 8);
    	var name = this.props.search.resultList[id].subject + "-" + userNumber;

    	var filename = name + ".txt";
	    function download(filename, text) {
	    	if (navigator.msSaveBlob) {
		      var blobObject = new Blob([text]);
		      window.navigator.msSaveBlob(blobObject, filename);
		    } else {
		    	var element = document.createElement('a');
			    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
			    element.setAttribute('download', filename);

			    element.style.display = 'none';
			    document.body.appendChild(element);

			    element.click();

			    document.body.removeChild(element);
		    }
		};

	    download(filename, text);
	}

	render() {
		return (
			<div className="col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
				<div className="row col-12">
					<div className="col-12" style={{paddingLeft: "0px"}}>
		                <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("tokenIdLabel")}</label>
		                <div className="col-12 row">
							<input className="form-control col-6" type="text" value={this.props.search.token} onChange={this.onInputChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
							<div>
			                	<div className="row" style={{marginLeft: "10px"}}>
									<button id="search" className="btn btn-info" onClick={this.handleSearch} style={{marginRight: "10px"}}>{i18next.t("searchLabel")}</button>
									<button className="btn btn-outline-info" onClick={this.handleCleanUp} style={{marginRight: "15px"}}>{i18next.t("cleanUpLabel")}</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-12" style={{marginTop: "50px", paddingLeft: "0px", paddingRight: "0px"}}>
					{
						this.props.search.resultList !== null && this.props.search.resultList.length > 0
						? <div className="col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
								<div className="col-12 table_div" style={{paddingLeft: "0px", paddingRight: "0px", height: "100%"}}>
									<div className="row fixed_header_div">
										<div className="col-3">{i18next.t("tokenIdLabel")}</div>
										<div className="col-2">{i18next.t("notBeforeLabel")}</div>
										<div className="col-2">{i18next.t("notAfterLabel")}</div>
										<div className="col-2">{i18next.t("statusLabel")}</div>
										<div className="col-2">{i18next.t("tokenOwnerLabel")}</div>
										<div className="col-1">{i18next.t("downloadLabel")}</div>
									</div>
									<div className="fixed_div">
										{
					            			this.props.search.resultList.map((token, index) => {
											    return (<div key={index} className="row" style={{marginTop: "5px"}}>
											    		<div className="col-3" style={{textAlign: "left"}}>{token.id}</div>
											    		<div className="col-2" style={{textAlign: "center"}}>{this.formatDate(token.notBefore)}</div>
											    		<div className="col-2" style={{textAlign: "center"}}>{this.formatDate(token.notAfter)}</div>
											    		<div className="col-2">
											    			{
											    				token.status === "REVOKED"
											    				? <div className="col-12 status-placeholder" disabled>
											    					{i18next.t("REVOKED")}
											    				</div>
											    				: <select type="select" className="form-control" defaultValue={token.status} style={{textAlign: "center", textAlignLast: "center"}} onChange={this.onStatusChanged.bind(this, token)}>
									                                {
									                                  this.state.statusValues.map((value, index) =>
									                                    <option key={index} value={value}>{i18next.t(value)}</option>)
									                                }          
									                            </select>
											    			}
							                            </div>
											    		<div className="col-2" style={{textAlign: "center", cursor: "pointer"}} onClick={this.getTokenOwner.bind(this, index)}>{token.subject}</div>
											    		<div className="col-1" style={{textAlign: "center", cursor: "pointer"}} onClick={this.getBlobData.bind(this, index)}><i className="fa fa-download"></i></div>
											    </div>)
											})
					            		}
					            	</div>
								</div>
								<div>
									{
										this.state.showPopup
										? <ConfirmPopup confirmChanges={this.changeStatusWithParams} closePopup={this.handleShowAlertMessage.bind(this)} />
							            : null
							        }
						        </div>
						</div>
						: null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        search: state.tokenSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	getTokenById,
    	formatDate, blockToken,
    	unblockToken, revokeToken,
    	getUser, getClient,
    	setSelectedUser, setAllUserFields,
    	setSelectedItem, changeSelectedTab,
    	setCreateUserMode, setShowCreateOrEditUserForm,
		clearTokensList, setShowUserTokensView,
		setShowTokens, setSelectedClient,
		setAllClientsFields,
		setShowClientTokensView,
		setCreateClientMode,
		setShowCreateOrEditForm,
		setResultList, clearResultList,
		setTokenForSearch, clearTokenForSearch
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokensSearchView);