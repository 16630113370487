import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getConfiguration } from '../actions/api'
import { setAllConfigFields } from '../actions/localStates'

import ConfigView from '../components/ConfigView';

class ConfigContainer extends Component {

	componentDidMount() {
		this.props.actions.getConfiguration(this.props.base.serviceUrl, this.props.login.accessToken)
    	.then((response) => {
    		if (response.code !== undefined) {
                alert(response.message);
                return
            }

            this.props.actions.setAllConfigFields(response);
    	})
	}

	render() {
		return (
			<ConfigView />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getConfiguration,
        setAllConfigFields
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigContainer);