import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTokens, createToken, createTokenForIssuer } from '../actions/api'
import { setTokensList, setShowTokens } from '../actions/localStates'

import { tokenUrl } from '../actions/helpers'

import TokensListView from '../components/TokensListView'

class TokensContainer extends Component {

	constructor(props) {
        super(props);

        this.state = {
        }

        this.createTokenRef = React.createRef();

        this.handleCreateToken = this.handleCreateToken.bind(this);
    }

	componentDidMount() {
		this.props.actions.setShowTokens(true);
        var params = {
            "url": this.props.base.serviceUrl,
            "type": "token",
            "page": 0,
            "size": 50,
            "subjectType": "USER",
            "subject": this.props.user.userName
        }
        this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
        .then((response) => {
            this.props.actions.setTokensList(response.content);
            console.log(response);
        })
	}

	handleCreateToken () {
        var user = {
            "subjectType" : "USER",
            "subjectId" : this.props.user.userName,
            "status": "ACTIVE"
        }
        this.props.actions.createTokenForIssuer(this.props.base.serviceUrl, user, this.props.login.accessToken)
        .then((response) => {
            if (response.code !== undefined) {
                alert(response.message);
                return
            }
        })
        .then(() => {
        	var params = {
                "url": this.props.base.serviceUrl,
                "type": "token",
                "page": 0,
                "size": 50,
                "subjectType": "USER",
                "subject": this.props.user.userName
            }
	        this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
	        .then((response) => {
	            this.props.actions.setTokensList(response.content);
	            console.log(response);
	        })
        })
    }

	render() {
		return (
			<TokensListView handleCreateToken={this.handleCreateToken} forwardRef={this.createTokenRef} />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        user: state.userReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getTokens,
	    createToken,
	    setTokensList,
	    setShowTokens,
        tokenUrl,
        createTokenForIssuer
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokensContainer);