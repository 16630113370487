import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setTwoFactorCode } from '../actions/localStates'

class TwoFactorPopup extends Component {

  constructor(props) {
      super(props);

      this.onTwoFactorCodeChanged = this.onTwoFactorCodeChanged.bind(this);

      this.loginConfirm = this.loginConfirm.bind(this);

      this.handle2FKeyUp = this.handle2FKeyUp.bind(this, 'twoFactorInput');
  }

  componentDidMount() {
      var element = document.getElementById("2f");
      element.focus();
      document.addEventListener('keyup', this.handle2FKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handle2FKeyUp);
  }

  handle2FKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "twoFactorInput") {
          document.getElementById("submitPin").click();
          document.activeElement.blur();
      }
  }

  onTwoFactorCodeChanged (e) {
    this.props.actions.setTwoFactorCode(e.target.value)
  }

  loginConfirm () {
    this.props.loginUser2f();
  }


  render() {
    let isEnabled = this.props.login.twoFactorCode >= 4
    ? true
    : false

      return (
          <div className='popup'>
              <div className={"popup_inner_user_token_pin"}>
                  <div className="col-12" style={{marginTop: "30px"}}>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingTop: "0px", paddingBottom: "0px"}}>{i18next.t("twoFactorPageTitle")}</label>
                          <div className="col-12 input-group">
                              <input id="2f" className="form-control" placeholder={i18next.t("oneTimeCodeTitle")} value={this.props.login.twoFactorCode} type="text" onChange={this.onTwoFactorCodeChanged} />
                          </div>

                      </div>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" id="submitPin" style={{marginLeft: "5px", marginBottom: "30px"}} disabled={!isEnabled} onClick={this.loginConfirm}>{i18next.t("signInTitle")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setTwoFactorCode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorPopup);