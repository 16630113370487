import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Cookies from 'universal-cookie';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import '../css/auth-web-client.css';

import { isIEOrEdge } from '../actions/helpers';
import { getDefaults, getVersion, getConfiguration, verifyToken } from '../actions/api';
import { setDefaults, setIsLoggedIn, setVersion, setIsIEBrowser, setAllConfigFields, setExpireInTime, setLoggedInUserData, setToken } from '../actions/localStates';

import HeaderContainer from './HeaderContainer';
import LoginContainer from './LoginContainer';
import BodyContainer from './BodyContainer';
import i18next from 'i18next'

const cookies = new Cookies();

class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			dataIsLoaded: false
		};

		this.login = this.login.bind(this);
    }

	login (token) {
		var userData;

		window.history.pushState({}, "");
		window.history.replaceState({}, document.title, "/");
        this.props.actions.getConfiguration(this.props.base.serviceUrl, token)
        .then((response) => {
            if (response.code !== undefined) {
                this.props.actions.setIsLoggedIn(false);
                alert(response.message);
                return
            }

            this.props.actions.setAllConfigFields(response);

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };
            this.props.actions.setToken(token);
            userData = parseJwt(token);

            this.props.actions.setLoggedInUserData(userData);

            this.props.actions.verifyToken(this.props.base.serviceUrl, token)
            .then((response) => {
                if (response.expires_in !== undefined) {
                    this.props.actions.setExpireInTime(response.expires_in)
                }

                this.props.actions.setIsLoggedIn(true);
                this.setState({"dataIsLoaded": true});
            })
        })
	}

	componentDidMount() {
		var token = cookies.get('tempTokenData');

		document.title = i18next.t("headerTitle");
		var isIE = this.props.actions.isIEOrEdge();
		if (isIE !== false) {
			this.props.actions.setIsIEBrowser(true);
		}

		this.props.actions.getDefaults()
		.then((response) => {
			this.props.actions.setDefaults(response.serviceVariables);
			this.props.actions.setIsLoggedIn(false);
			if (token !== undefined && token.length > 0) {
				cookies.remove('tempTokenData', { path: '/', domain: "." + response.serviceVariables.domain })
			}
		})
		.then(() => {
			this.props.actions.getVersion()
			.then((response) => {
				this.props.actions.setVersion(response)
				if (token !== undefined && token.length > 0) {
					this.login(token)
				} else {
					this.setState({"dataIsLoaded": true})
				}
			})
		})
	}

	render() {
		return (
			this.props.base.defaultsSetted && this.state.dataIsLoaded
			? <div className="body-container">
				<HeaderContainer />
					{
						this.props.login.isLoggedIn
						? <BodyContainer />
						: <LoginContainer />
					}
			</div>
			: null
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getDefaults,
		setDefaults,
		setIsLoggedIn,
		getVersion,
		setVersion,
		isIEOrEdge,
		setIsIEBrowser,
		getConfiguration,
		verifyToken,
		setAllConfigFields, setExpireInTime,
		setLoggedInUserData,
		setToken
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);