import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { clearSelectedUser, clearSelectedClient, clearAllUserFields, clearAllClientsFields, clearTokensList,
			setShowCreateOrEditForm, setShowCreateOrEditUserForm, setSelectedItem, setShowTokens, setShowUserTokensView,
			setShowClientTokensView, setCreateClientMode, setCreateUserMode } from '../actions/localStates';

import BreadcrumbsView from '../components/BreadcrumbsView';

class BreadcrumbsContainer extends Component {

	constructor(props) {
    super(props);

        this.state = {
            token: ""
        }

        this.returnRef = React.createRef();

        this.returnToPrevious = this.returnToPrevious.bind(this);
        this.returnToUserOrClient = this.returnToUserOrClient.bind(this);
    }

	componentDidMount() {

	}

	returnToPrevious () {
		this.props.actions.clearSelectedUser();
		this.props.actions.clearSelectedClient();
		this.props.actions.clearAllUserFields();
		this.props.actions.clearAllClientsFields();
		this.props.actions.clearTokensList();
		this.props.actions.setShowCreateOrEditForm(false);
		this.props.actions.setShowCreateOrEditUserForm(false);
		this.props.actions.setCreateClientMode(false);
		this.props.actions.setCreateUserMode(false);
		this.props.actions.setSelectedItem(null);
		this.props.actions.setShowTokens(false);
		this.props.actions.setShowUserTokensView(false);
		this.props.actions.setShowClientTokensView(false);
	}

	returnToUserOrClient () {
		this.props.actions.setShowTokens(false);
		this.props.actions.setShowUserTokensView(false);
		this.props.actions.setShowClientTokensView(false);
	}

	render() {
		return (
			<div>
				<BreadcrumbsView returnToPreviousTab={this.returnToPrevious} returnToPreviousUserOrClientTab={this.returnToUserOrClient} forwardRef={this.returnRef} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    clearSelectedUser, clearSelectedClient,
	    clearAllUserFields, clearAllClientsFields,
	    clearTokensList,
	    setShowCreateOrEditForm,
		setShowCreateOrEditUserForm,
		setSelectedItem,
		setShowTokens,
		setShowUserTokensView,
		setShowClientTokensView,
		setCreateClientMode,
		setCreateUserMode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsContainer);