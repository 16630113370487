const baseState = {
    isLoggedIn: false,
    login: "",
    password: "",
    accessToken: null,
    loggedInUserData: null,
    loggedInUserName: null,
    loggedInUserId: null,
    twoFactorEnabled: false,
    twoFactorCode: ""
}

const loginReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_IS_LOGGED_IN": {
			return {
				...state,
				isLoggedIn: payload
			}
		}

		case "SET_LOGIN_INPUT": {
			return {
				...state,
				login: payload
			}
		}

		case "SET_PASSWORD_INPUT": {
			return {
				...state,
				password: payload
			}
		}

		case "SET_TOKEN": {
			return {
				...state,
				accessToken: payload
			}
		}

		case "CLEAR_TOKEN": {
			return {
				...state,
				accessToken: null
			}
		}

		case "SET_LOGGED_IN_USER_DATA": {
			return {
				...state,
				loggedInUserData: payload,
				loggedInUserName: payload.sfn,
				loggedInUserId: payload.sub
			}
		}

		case "CLEAR_LOGGED_IN_USER_DATA": {
			return {
				...state,
				loggedInUserData: null,
				loggedInUserName: null,
				loggedInUserId: null,
				twoFactorEnabled: false
			}
		}

		case "SET_TWO_FACTOR_ENABLED": {
			return {
				...state,
				twoFactorEnabled: payload
			}
		}

		case "CLEAR_TWO_FACTOR_ENABLED": {
			return {
				...state,
				twoFactorEnabled: false
			}
		}

		case "SET_TWO_FACTOR_CODE": {
			return {
				...state,
				twoFactorCode: payload
			}
		}

		case "CLEAR_TWO_FACTOR_CODE": {
			return {
				...state,
				twoFactorCode: ""
			}
		}

        default:
            return state;
    }
}

export default loginReducer