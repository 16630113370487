import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setSelectedClient, setAllClientsFields, setShowCreateOrEditForm, clearAllClientsFields, setCreateClientMode, setClientsList, setALLClientsPaginationParams,
			setPageSize, changeSearchState, setIsLoggedIn, setClientPaginationParams, clearClientsList, setClientPageSize } from '../actions/localStates'

import { getClient, getClients, unblockClient, blockClient, revokeClient, getClientsWithParams } from '../actions/api';

import { searchUrl, formatDate } from '../actions/helpers';

import ClientSearchContainer from '../containers/ClientSearchContainer'
import ConfirmPopup from './ConfirmPopup'

import first from '../img/first.svg'
import last from '../img/last.svg'

let searchBar;

class ClientsListView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	statusValues: ["ACTIVE", "BLOCKED", "REVOKED"],
	    	searchOpen: false,
	    	showSearchForm: false,
	    	elementsCountArray: [10, 20, 50],
	    	showItemsCountForm: false,
	    	current_page: 1,
	    	current_client: null,
	    	current_event: null,
	    	showPopup: false,
	    	records_per_page: this.props.page.size
	    }

	    this.handleShowCreateForm = this.handleShowCreateForm.bind(this);
	    this.handleSearchClick = this.handleSearchClick.bind(this);
	    this.handleShowCountForm = this.handleShowCountForm.bind(this);
	    this.handleShowAlertMessage = this.handleShowAlertMessage.bind(this);
	    this.changeStatusWithParams = this.changeStatusWithParams.bind(this);

	    this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.firstPage = this.firstPage.bind(this);
		this.lastPage = this.lastPage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.numPages = this.numPages.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
	}

	handleShowAlertMessage () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });

	    var params = {
			url: this.props.base.serviceUrl,
			type: "client",
			page: this.props.page.number,
			size: this.props.page.size,
			clientName: this.props.search.clientName || "",
			fullName: this.props.search.clientId || "",
			status: this.props.search.clientStatus || ""
		}

		this.props.actions.getClientsWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
		.then((response) => {
			if (response === null) {
				this.props.actions.setIsLoggedIn(false);
				return
			}
			this.props.actions.clearClientsList()
			this.props.actions.setClientsList(response.content)
			this.props.actions.setALLClientsPaginationParams(response.page)
		})
	}

	prevPage ()	{
		if (this.props.page.number > 0) {
	        this.changePage(this.props.page.number - 1);
	    }
	}

	nextPage () {
		if (this.props.page.number < this.numPages()) {
	        this.changePage(this.props.page.number + 1);
	    }
	}

	firstPage ()	{
	    this.changePage(0);
	}

	lastPage ()	{
	    this.changePage(this.numPages() - 1);
	}

	changePage (page) {

		if (this.state.showItemsCountForm === true) {
			this.setState({showItemsCountForm: false})
		}

		if (page < 0) page = 0;
	    if (page >= this.numPages()) page = this.numPages() - 1;

	    var params = {
			url: this.props.base.serviceUrl,
			type: "client",
			page: page,
			size: this.props.page.size,
			clientName: this.props.search.clientName || "",
			fullName: this.props.search.clientId || "",
			status: this.props.search.clientStatus || ""
		}

		this.props.actions.getClientsWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
		.then((response) => {
			this.props.actions.setClientsList(response.content)
			this.props.actions.setALLClientsPaginationParams(response.page)
		})		
	}

	numPages () {
	    return Math.ceil(this.props.page.totalElements / this.props.page.size);
	}

	componentDidMount() {
		var _this = this;
		this.setState({searchOpen: false})
		this.props.actions.changeSearchState(false);
		searchBar = document.getElementById("search-bar");
		searchBar.addEventListener( 'webkitTransitionEnd', function( event ) { _this.setState({showSearchForm: _this.state.searchOpen}) }, false );

		if (this.props.page.clientsPaginationParamsSetted) {
		    var params = {
				url: this.props.base.serviceUrl,
				type: "client",
				page: this.props.page.number,
				size: this.props.page.size,
				clientName: this.props.search.clientName || "",
				fullName: this.props.search.clientId || "",
				status: this.props.search.clientStatus || ""
			}
			console.log(this.props.actions.searchUrl(params))
			this.props.actions.getClientsWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
			.then((response) => {
				console.log(response);
				this.props.actions.setClientsList(response.content)
				this.props.actions.setALLClientsPaginationParams(response.page)
			})
		} else {
			this.props.actions.getClients(this.props.base.serviceUrl, this.props.login.accessToken)
			.then((response) => {
				if (response === null) {
					this.props.actions.setIsLoggedIn(false);
					return
				}
				this.props.actions.setClientsList(response.content)
				this.props.actions.setALLClientsPaginationParams(response.page)
				this.props.actions.setCreateClientMode(false)
				this.props.actions.setClientPaginationParams(true)
				console.log(response);
			})
		}
	}

	formatDate(dateInString) {
        return this.props.actions.formatDate(dateInString);
    }

    handleShowCreateForm () {
    	this.props.actions.setShowCreateOrEditForm(true)
    	this.props.actions.setCreateClientMode(true)
    	this.props.actions.clearAllClientsFields()
    }

    handleSearchClick () {
		this.setState({searchOpen: !this.state.searchOpen})
		this.props.actions.changeSearchState(!this.state.searchOpen);
		this.setState({showSearchForm: false})
	}

	handleShowCountForm () {
		this.setState({showItemsCountForm: !this.state.showItemsCountForm})
	}

	handleCountChanged (value) {
		var page
		if (this.props.page.number < 1) page = 1;
	    if (this.props.page.number > this.numPages()) page = this.numPages();
		var params = {
			url: this.props.base.serviceUrl,
			type: "client",
			page: page - 1,
			size: value
		}
		this.props.actions.setClientPageSize(value);
		this.setState({showItemsCountForm: !this.state.showItemsCountForm});
		console.log(this.props.actions.searchUrl(params));

		this.props.actions.getClientsWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
		.then((response) => {
			this.props.actions.setClientsList(response.content)
			this.props.actions.setALLClientsPaginationParams(response.page)
			this.props.actions.setClientPageSize(response.page.size)
			this.numPages();
			this.changePage(this.props.page.number);
		})
	}

	changeStatus(client, value) {
		var currentAction;

		document.activeElement.blur();

		switch (value)
		{
		    case "ACTIVE":
		        currentAction = this.props.actions.unblockClient(this.props.base.serviceUrl, client.clientName, this.props.login.accessToken)
		        break;

		    case "BLOCKED":
		        currentAction = this.props.actions.blockClient(this.props.base.serviceUrl, client.clientName, this.props.login.accessToken)
		        break;

		    case "REVOKED":
		        currentAction = this.props.actions.revokeClient(this.props.base.serviceUrl, client.clientName, this.props.login.accessToken)
		        break;

		    default:
		        currentAction = this.props.actions.unblockClient(this.props.base.serviceUrl, client.clientName, this.props.login.accessToken)
		        break;
		}

		currentAction
		.then(() => {
			var params = {
				url: this.props.base.serviceUrl,
				type: "client",
				page: this.props.page.number,
				size: this.props.page.size,
				clientName: this.props.search.clientName || "",
				fullName: this.props.search.clientId || "",
				status: this.props.search.clientStatus || ""
			}
			console.log(this.props.actions.searchUrl(params))
			this.props.actions.getClientsWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
			.then((response) => {
				console.log(response);
				this.props.actions.clearClientsList()
				this.props.actions.setClientsList(response.content)
				this.props.actions.setALLClientsPaginationParams(response.page)
			})

			if (this.state.showPopup) {
				this.setState({"showPopup": false})
			}
		})
	}

    onStatusChanged (client, e) {
		if (e.target.value === "REVOKED") {
			this.setState({"current_client": client})
			this.setState({"current_event": e.target.value})
			this.handleShowAlertMessage()
		} else {
			this.changeStatus(client, e.target.value)
		}
	}

	changeStatusWithParams () {
		this.changeStatus(this.state.current_client, this.state.current_event)
	}

    getSelectedClient (name) {
		this.props.getClient(name)
	}

	render() {
		let searchBarOpenState = 'searh-bar'
		let itemsCountFormOpen = 'dropdown-menu'
		let useIEStyle = 'offset-8 bottomright'

		if (this.props.base.isIEBrowser) {
			useIEStyle = 'offset-8 bottomright-ie'
		}

		if (this.props.base.searchIsOpen) {
		    searchBarOpenState = 'searh-bar open'
		}

		if (this.state.showItemsCountForm) {
			itemsCountFormOpen = 'dropdown-menu show'
		}
		return (
			<div>
				<div style={{paddingBottom: "30px"}}>
					<button className="btn btn-outline-info" style={{float: "right", marginRight: "15px"}} onClick={this.handleShowCreateForm}>{i18next.t("addNewClientLabel")}</button>&nbsp;
				</div>
				<div id="search-bar" className={searchBarOpenState}>
					<div style={{"cursor": "pointer", marginTop: "-5px", marginRight: "15px"}}>
						<div style={{float: "right"}} onClick={this.handleSearchClick}>
							{
								this.state.searchOpen
								? <i className="fa fa-chevron-up"></i>
								: <i className="fa fa-chevron-down"></i>
							}
						</div>
					</div>
					<div style={{paddingTop: "20px"}}>
						{
							this.state.showSearchForm
							? <ClientSearchContainer />
							: null
						}
					</div>
				</div>
				<div className="table-holder">
					<div className="col-12 table_div" style={{paddingLeft: "0px", paddingRight: "0px", height: "100%"}}>
						<div className="row fixed_header_div">
							<div className="col-3">{i18next.t("clientUniqueIdLabel")}</div>
							<div className="col-3">{i18next.t("clientStatusLabel")}</div>
							<div className="col-3">{i18next.t("advancedInfo")}</div>
							<div className="col-3">{i18next.t("createdDateLabel")}</div>
						</div>
						<div className="fixed_div">
							{
		            			this.props.clients.clientsList.map((client, index) => {
							    return (<div key={index} className="row" style={{marginTop: "5px"}}>
							    		<div className="col-3" style={{textAlign: "left", cursor: "pointer"}} onClick={this.getSelectedClient.bind(this, client)}>{client.clientName}</div>
							    		<div className="col-3">
							    			{
								    				client.status === "REVOKED"
								    				? <div className="col-12 status-placeholder" disabled>
								    					{i18next.t("REVOKED")}
								    				</div>
								    				: <select type="select" className="form-control" defaultValue={client.status} style={{textAlign: "center", textAlignLast: "center"}} onChange={this.onStatusChanged.bind(this, client)}>
						                                {
						                                  this.state.statusValues.map((value, index) =>
						                                    <option key={index} selected={client.status === value ? true : false} value={value}>{i18next.t(value)}</option>)
						                                }          
						                            </select>
								    			}
			                            </div>
							    		<div className="col-3" style={{textAlign: "center"}}>{client.extendedData}</div>
							    		<div className="col-3" style={{textAlign: "center"}}>{this.formatDate(client.createdDate)}</div>
							    </div>)
							})
		            		}
		            	</div>
					</div>
				</div>

				{
					this.numPages() === 0
					? null
					: <div className={useIEStyle}>
						<div className="row">
							<div className="btn-group dropup" style={{minWidth: "5rem"}}>
								<button type="button" onClick={this.handleShowCountForm} className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showItemsCountForm}>
									{
										this.props.page.size
									}
								</button>
								<div className={itemsCountFormOpen} style={{minWidth: "5rem"}}>
									{
										this.state.elementsCountArray.map((value, index) => {
											return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleCountChanged.bind(this, value)}>{value}</span>)
										})
									}
								</div>
							</div>
							<div className="row" style={{width: "255px", textAlign: "center"}}>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.firstPage}>
									<img className="img" style={{marginTop: "5px"}} src={first} width="20" height="26" alt={first}/>
								</div>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "5px"}} onClick={this.prevPage}>
									<i className="fa fa-chevron-left"></i>
								</div>
								<div className="col-5" style={{paddingTop: "5px", textAlign: "center", paddingLeft: "0px", paddingRight: "0px"}}>
									{
										this.props.page.number + 1
									}
									<span style={{paddingTop: "5px"}}> / </span>
									{
										this.numPages()
									}
								</div>
								
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "5px", paddingLeft: "0px"}} onClick={this.nextPage}>
									<i className="fa fa-chevron-right"></i>
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingLeft: "0px"}} onClick={this.lastPage}>
									<img className="img" style={{marginTop: "5px"}} src={last} width="20" height="26" alt={last}/>
								</div>
							</div>
							<div>
								{
									this.state.showPopup
									? <ConfirmPopup confirmChanges={this.changeStatusWithParams} closePopup={this.handleShowAlertMessage.bind(this)} />
						            : null
						        }
					        </div>
						</div>
					</div>
				}	
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        clients: state.clientsReducer,
        client: state.clientReducer,
        login: state.loginReducer,
        page: state.clientPaginationReducer,
        search: state.clientSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    setSelectedClient,
		setAllClientsFields,
		getClient,
		setShowCreateOrEditForm,
		clearAllClientsFields,
		setCreateClientMode, unblockClient,
		blockClient, revokeClient,
		getClients,
		setClientsList,
		setALLClientsPaginationParams,
		setPageSize,
		getClientsWithParams,
		searchUrl,
		changeSearchState,
		setIsLoggedIn,
		formatDate,
		setClientPaginationParams,
		clearClientsList,
		setClientPageSize
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsListView);