const baseState = {
    activeTab: "",
    selectedItem: null,
    showTokens: false,
    isClientTokens: false
}

const breadcrumbsReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_ACTIVE_TAB": {
			return {
				...state,
				activeTab: payload
			}
		}

		case "SET_SELECTED_ITEM": {
			return {
				...state,
				selectedItem: payload
			}
		}

		case "SET_SHOW_TOKENS": {
			return {
				...state,
				showTokens: payload
			}
		}

		case "SET_SHOW_CLIENTS_TOKENS": {
			return {
				...state,
				isClientTokens: payload
			}
		}

        default:
            return state;
    }
}

export default breadcrumbsReducer