import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import clientsReducer from './clientsReducer';
import userReducer from './userReducer';
import clientReducer from './clientReducer';
import loginReducer from './loginReducer';
import breadcrumbsReducer from './breadcrumbsReducer';
import tokensReducer from './tokensReducer';
import userSearchReducer from './userSearchReducer';
import userPaginationReducer from './userPaginationReducer';
import ConfigReducer from './ConfigReducer'
import clientSearchReducer from './clientSearchReducer';
import clientPaginationReducer from './clientPaginationReducer';
import tokenPaginationReducer from './tokenPaginationReducer';
import tokenSearchReducer from './tokenSearchReducer';
import userFormReducer from './userFormReducer'

const baseState = {
    drawerOpen: false,
    selectedTab: 0,
    createClientMode: false,
    createUserMode: false,
    showCreateOrEditClientForm: false,
    showCreateOrEditUserForm: false,
    serviceUrl: "",
    clientId: "",
    defaultsSetted: false,
    version: "",
    build: "",
    searchIsOpen: false,
    isIEBrowser: false,
    domain: "",
    apps: [],
    showSessionEndHandler: false,
    needToLogout: false,
    needReLogin: false,
    waitingTime: 0,
    timeToLogout: 15,
    numberOfPinInput: 3
}

const base = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "CHANGE_DRAWER_STATE": {
			return {
				...state,
				drawerOpen: payload
			}
		}

        case "CHANGE_SEARCH_STATE": {
            return {
                ...state,
                searchIsOpen: payload
            }
        }

		case "CHANGE_SELECTED_TAB": {
			return {
				...state,
				selectedTab: payload
			}
		}

        case "SET_CREATE_CLIENT_MODE": {
            return {
                ...state,
                createClientMode: payload
            }
        }

        case "SET_CREATE_USER_MODE": {
            return {
                ...state,
                createUserMode: payload
            }
        }

        case "SET_SHOW_CREATE_OR_EDIT_FORM": {
            return {
                ...state,
                showCreateOrEditClientForm: payload
            }
        }

        case "SET_SHOW_CREATE_OR_EDIT_USER_FORM": {
            return {
                ...state,
                showCreateOrEditUserForm: payload
            }
        }

        case "SET_DEFAULTS": {
            return {
                ...state,
                serviceUrl: payload.serviceUrl,
                clientId: payload.clientId,
                defaultsSetted: true,
                apps: payload.apps || [],
                domain: "." + payload.domain || "",
                waitingTime: parseInt(payload.waitingTimeSec, 10) || 30,
                timeToLogout: parseInt(payload.timeToLogoutMin, 10) || 15,
                numberOfPinInput: parseInt(payload.numberOfPinInput, 10) || 3
            }
        }

        case 'SET_VERSION': {
            return {
                ...state,
                version: payload.version,
                build: payload.build
            }
        }

        case 'SET_IS_IE_BROWSER': {
            return {
                ...state,
                isIEBrowser: payload
            }
        }

        case "SET_NEED_TO_LOGOUT": {
            return {
                ...state,
                needToLogout: payload
            }
        }

        case "SET_NEED_RELOGIN": {
            return {
                ...state,
                needReLogin: payload
            }
        }

        case "SET_SHOW_SESSION_END_HANDLER": {
            return {
                ...state,
                showSessionEndHandler: payload
            }
        }

        default:
            return state;
    }

}

export default combineReducers({
    base: base,
    usersReducer,
    clientsReducer,
    userReducer,
    clientReducer,
    loginReducer,
    breadcrumbsReducer,
    tokensReducer,
    userSearchReducer,
    userPaginationReducer,
    ConfigReducer,
    clientSearchReducer,
    clientPaginationReducer,
    tokenPaginationReducer,
    tokenSearchReducer,
    userFormReducer
});


