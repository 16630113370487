import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pageUrl, searchUrl } from '../actions/helpers';
import { getClientsWithParams, getClients } from '../actions/api';
import { setClientsList, setALLClientsPaginationParams } from '../actions/localStates'

import ClientSearchView from '../components/ClientSearchView'

class ClientSearchContainer extends Component {

	constructor(props) {
    super(props);

        this.state = {
        }

        this.searchRef = React.createRef();

        this.searchClient = this.searchClient.bind(this);
        this.getClients = this.getClients.bind(this);
    }

	componentDidMount() {

	}

	getClients() {
		this.props.actions.getClients(this.props.base.serviceUrl, this.props.login.accessToken)
		.then((response) => {
			if (response === null) {
				this.props.actions.setIsLoggedIn(false);
				return
			}
			this.props.actions.setClientsList(response.content)
			this.props.actions.setALLClientsPaginationParams(response.page)
		})
	}

	searchClient() {
		var params = {
			url: this.props.base.serviceUrl,
			type: "client",
			size: this.props.page.size,
			clientName: this.props.search.clientName || "",
			fullName: this.props.search.clientId || "",
			status: this.props.search.clientStatus || ""
		}
		console.log(this.props.actions.searchUrl(params))
		this.props.actions.getClientsWithParams(this.props.actions.searchUrl(params), this.props.login.accessToken)
		.then((response) => {
			console.log(response);
			this.props.actions.setClientsList(response.content)
			this.props.actions.setALLClientsPaginationParams(response.page)
		})
	}

	render() {
		return (
			<div>
				<ClientSearchView searchClient={this.searchClient} refresh={this.getClients} forwardRef={this.searchRef} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        page: state.clientPaginationReducer,
        search: state.clientSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    pageUrl,
	    searchUrl,
	    getClients,
	    getClientsWithParams,
	    setClientsList, setALLClientsPaginationParams
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientSearchContainer);