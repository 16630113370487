import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TokensSearchView from '../components/TokensSearchView'

class TokensSearchContainer extends Component {

	componentDidMount() {

	}

	render() {
		return (
			<div>
				<TokensSearchView />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokensSearchContainer);