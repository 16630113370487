import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { updateUser, createUser, getTokens, login } from '../actions/api';
import { clearSelectedUser, setShowCreateOrEditUserForm, setCreateUserMode, setTokensList, clearTokensList, setSelectedItem } from '../actions/localStates';

import CreateEditUserView from '../components/CreateEditUserView'
import TokensContainer from './TokensContainer'

class UserContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.createEditUserRef = React.createRef();

        this.createEditUser = this.createEditUser.bind(this);
    }

	componentDidMount() {

	}

    createEditUser () {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
        var userData = {
            "userName": this.props.user.userName || "",
            "firstName": this.props.user.userFirstName !== undefined && this.props.user.userFirstName.length > 0 ? this.props.user.userFirstName.replace("\\s+", " ").trim() : "",
            "secondName": this.props.user.userSecondName !== undefined && this.props.user.userSecondName.length > 0 ? this.props.user.userSecondName.replace("\\s+", " ").trim() : "",
            "password": this.props.user.userPassword,
            "roles": this.props.user.userRole.length > 0 ? this.props.user.userRole : [],
            "status": this.props.user.userStatus || "ACTIVE",
            "createdDate": (this.props.user.userCreatedDate !== undefined && this.props.user.userCreatedDate.length > 0) ? this.props.user.userCreatedDate : localISOTime,
            "activeTokenNumber": this.props.user.userActiveTokenNumber,
            "tokenExpiredAfterSec": this.props.user.userTokenExpiredAfterSec,
            "position": this.props.user.userPosition,
            "department": this.props.user.userDepartment,
            "phone": this.props.user.phoneNumber.length > 0 ? this.props.user.phoneNumber.replace(/-/g, "") : "",
            "extendedData": this.props.user.userExtendedData,
            "email": this.props.user.userEmail,
            "twoFactorChannelId": this.props.user.twoFactorChannelId || null,
            "twoFactorSettings": this.props.user.twoFactorSettings || null

        }

        this.props.base.createUserMode
        ? this.props.actions.createUser(this.props.base.serviceUrl, userData, this.props.login.accessToken)
            .then((response) => {
                if (response.code !== undefined) {
                    alert(response.message);
                    return
                }
                alert(i18next.t("userSuccessfullyCreatedMessage"));
                this.props.actions.setShowCreateOrEditUserForm(false);
                this.props.actions.setCreateUserMode(false);
                this.props.actions.setSelectedItem(null);
                this.props.actions.clearSelectedUser();
            })
        : this.props.actions.updateUser(this.props.base.serviceUrl, userData, this.props.login.accessToken)
            .then((response) => {
                console.log(response);
                alert(i18next.t("changesSavedMessage"));
                this.props.actions.setShowCreateOrEditUserForm(false);
                this.props.actions.setSelectedItem(null);
                this.props.actions.clearSelectedUser();
            })
    }

	render() {
		return (
            <div>
                {
                    this.props.user.showUserTokenView
                    ? <TokensContainer />
                    : <CreateEditUserView createEditUser={this.createEditUser} forwardRef={this.createEditUserRef} />
                }
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        user: state.userReducer,
        tokens: state.tokensReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        updateUser,
        clearSelectedUser,
        setShowCreateOrEditUserForm,
        createUser,
        setCreateUserMode,
        getTokens,
        login, setTokensList,
        clearTokensList,
        setSelectedItem
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);