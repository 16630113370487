import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUsers, getUser } from '../actions/api';
import { setUsersList, setSelectedUser, setAllUserFields, setShowCreateOrEditUserForm, setSelectedItem, setCreateUserMode, setALLPaginationParams,
			setIsLoggedIn, setPhoneParams, clearPhoneParams, setPhoneNumber } from '../actions/localStates'

import UsersListView from '../components/UsersListView';
import UserContainer from './UserContainer';

class UsersContainer extends Component {
	constructor(props) {
    super(props);

	    this.state = {
		}

		this.child = React.createRef();

		this.getSelectedUser = this.getSelectedUser.bind(this);
	}

	componentDidMount() {
		
	}

	getSelectedUser (user) {
		var name = "?userName=" + encodeURI(user.userName) + "&status=" + user.status;
    	this.props.actions.getUser(this.props.base.serviceUrl, name, this.props.login.accessToken)
    	.then((response) => {
    		var index = 0;
    		if (response.content.length === 0) {
    			console.log("Broken user");
    			return;
    		}

    		for(var i = 0; i < response.content.length; i++) {
			    if (response.content[i].userName === user.userName) {
			        index = i;
			        break;
			    }
			}
    		this.props.actions.clearPhoneParams()
   			
   			if (response.content[index].phone !== undefined) {
   				this.props.actions.setPhoneNumber(response.content[index].phone);
   			}
   
    		this.props.actions.setSelectedUser(response.content[index]);
    		this.props.actions.setAllUserFields(response.content[index]);
    		this.props.actions.setSelectedItem(response.content[index].userName);
    		
    		console.log(response);
    		this.props.actions.setShowCreateOrEditUserForm(true);
    		this.props.actions.setCreateUserMode(false);

    	})
    }

	render() {
		return (
			<div>
				{
					this.props.base.showCreateOrEditUserForm
					? <UserContainer />
					: <UsersListView getUser={this.getSelectedUser} forwardRef={this.child} />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getUsers,
	    setUsersList,
	    getUser,
	    setSelectedUser,
	    setAllUserFields,
	    setShowCreateOrEditUserForm,
	    setSelectedItem,
	    setCreateUserMode,
	    setALLPaginationParams,
	    setIsLoggedIn,
	    setPhoneParams,
	    clearPhoneParams,
	    setPhoneNumber
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);