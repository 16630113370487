import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ClientsContainer from '../containers/ClientsContainer';
import UsersContainer from '../containers/UsersContainer';
import TokensSearchContainer from '../containers/TokensSearchContainer';
import ConfigContainer from '../containers/ConfigContainer';
import BreadcrumbsContainer from '../containers/BreadcrumbsContainer'
import SessionEndPopup from './SessionEndPopup'

const activeElementsArray = [<UsersContainer />, <ClientsContainer />, <TokensSearchContainer />, <ConfigContainer />];

class Body extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	activeElement: 0
	    }
	}

	componentDidMount() {

	}

	render() {
		let drawerClasses = 'body-width'
		if (this.props.base.drawerOpen) {
		    drawerClasses = 'body-width open'
		}
		return (
			<div className={drawerClasses}>
				<BreadcrumbsContainer />
  				{activeElementsArray[this.props.base.selectedTab]}
  				{
					this.props.base.showSessionEndHandler
					? <SessionEndPopup />
		            : null
		        }
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);