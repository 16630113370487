import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next';

const selectedElementName = ["usersTitle", "clientsTitle", "tokensTitle", "configTitle"];

class BreadcrumbsView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	activeElement: 0
	    }
	}

	componentDidMount() {

	}

	returnToPrevious () {
		this.props.returnToPreviousTab();
	}

	returnToPreviousUserOrClientTab () {
		this.props.returnToPreviousUserOrClientTab()
	}

	render() {
		return (
			<div>
				<ol className="breadcrumb">
					<li className={this.props.breadcrumbs.selectedItem ? "breadcrumb-item" : "breadcrumb-item active"} style={{cursor: "pointer"}} onClick={this.returnToPrevious.bind(this)}>
						{
							i18next.t(selectedElementName[this.props.base.selectedTab])
						}
					</li>
					{
	  					
	  					this.props.breadcrumbs.selectedItem
	  					? <li className={this.props.breadcrumbs.showTokens ? "breadcrumb-item" : "breadcrumb-item active"} style={{cursor: "pointer"}} onClick={this.returnToPreviousUserOrClientTab.bind(this)}>
	  						{
	  							this.props.breadcrumbs.selectedItem
	  						}
	  					</li>
	  					: null
	  				}
	  				{
	  					
	  					this.props.breadcrumbs.showTokens
	  					? <li className="breadcrumb-item active">{i18next.t("tokensTitle")}</li>
	  					: null
	  				}
				</ol>
				<span>
					
				</span>
				<span>
					
				</span>
				<span>
					
				</span>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        breadcrumbs: state.breadcrumbsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsView);