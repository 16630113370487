const baseState = {
    selectedUser: null,
    userName: "",
    userPassword: "",
    userStatus: "",
    userRole: [],
    userCreatedDate: "",
    userActiveTokenNumber: 0,
    userTokenExpiredAfterSec: 0,
    userFirstName: "",
    userSecondName: "",
    userPosition: "",
    userDepartment: "",
    phoneIndex: "+38",
    phoneCode: "050",
    phoneNumber: "",
    userPhone: "",
    confirmedProfile: null,
    userExtendedData: "",
    userEmail: "",
    showUserTokenView: false,
    twoFactorChannelId: null,
    twoFactorSettings: null,
    twoFactorAvailabelChannels: null
}

const userReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_SELECTED_USER": {
			return {
				...state,
				selectedUser: payload
			}
		}

		case "SET_SHOW_USER_TOKENS_VIEW": {
			return {
				...state,
				showUserTokenView: payload
			}
		}

		case "SET_ALL_USER_FIELDS": {
			return {
				...state,
				userName: payload.userName,
			    userStatus: payload.status,
			    userRole: payload.roles,
			    userCreatedDate: payload.createdDate,
			    userActiveTokenNumber: payload.activeTokenNumber,
			    userTokenExpiredAfterSec: payload.tokenExpiredAfterSec,
			    userFirstName: payload.firstName,
			    userSecondName: payload.secondName,
			    userPosition: payload.position,
			    userDepartment: payload.department,
			    userPhone: payload.phone,
			    userPassword: payload.password,
			    userEmail: payload.email,
			    userExtendedData: payload.extendedData,
			    twoFactorChannelId: payload.twoFactorChannelId || null,
				twoFactorSettings: payload.twoFactorSettings || null,
				confirmedProfile: payload.confirmedProfile || null
			}
		}

		case "CLEAR_ALL_USER_FIELDS": {
			return {
				...state,
				userName: "",
				userPassword: "",
			    userStatus: "",
			    userRole: [],
			    userCreatedDate: "",
			    userActiveTokenNumber: 0,
			    userTokenExpiredAfterSec: 0,
			    userFirstName: "",
			    userSecondName: "",
			    userPosition: "",
			    userDepartment: "",
			    userPhone: "",
			    userEmail: "",
			    userExtendedData: "",
			    twoFactorChannelId: null,
				twoFactorSettings: null,
				confirmedProfile: null
			}
		}

		case "CLEAR_SELECTED_USER": {
			return {
				...state,
				selectedUser: null
			}
		}

		case "SET_PHONE_PARAMS": {
			return {
				...state,
				phoneIndex: payload.index,
			    phoneCode: payload.code,
			    phoneNumber: payload.number
			}
		}

		case "CLEAR_PHONE_PARAMS": {
			return {
				...state,
			    phoneCode: "050",
			    phoneNumber: ""
			}
		}

		case "SET_PHONE_CODE": {
			return {
				...state,
			    phoneCode: payload
			}
		}

		case "SET_PHONE_NUMBER": {
			return {
				...state,
			    phoneNumber: payload
			}
		}

		case "SET_USER_NAME": {
			return {
				...state,
				userName: payload
			}
		}

		case "SET_USER_FIRST_NAME": {
			return {
				...state,
				userFirstName: payload
			}
		}

		case "SET_USER_SECOND_NAME": {
			return {
				...state,
				userSecondName: payload
			}
		}

		case "SET_USER_STATUS": {
			return {
				...state,
				userStatus: payload
			}
		}

		case "SET_USER_ROLE": {
			return {
				...state,
				userRole: payload
			}
		}

		case "SET_ACTIVE_TOKEN_NUMBER": {
			return {
				...state,
				userActiveTokenNumber: payload
			}
		}

		case "SET_TOKEN_EXPIRED_AFTER_SEC": {
			return {
				...state,
				userTokenExpiredAfterSec: payload
			}
		}

		case "SET_POSITION": {
			return {
				...state,
				userPosition: payload
			}
		}

		case "SET_DEPARTMENT": {
			return {
				...state,
				userDepartment: payload
			}
		}

		case "SET_PHONE": {
			return {
				...state,
				userPhone: payload
			}
		}

		case "SET_EXTENDED_DATA": {
			return {
				...state,
				userExtendedData: payload
			}
		}

		case "SET_CREATED_DATE": {
			return {
				...state,
				userCreatedDate: payload
			}
		}

		case "SET_USER_PASSWORD": {
			return {
				...state,
				userPassword: payload
			}
		}

		case "SET_USER_EMAIL": {
			return {
				...state,
				userEmail: payload
			}
		}

		case "SET_TWO_FACTOR_CHANNEL_ID": {
			return {
				...state,
				twoFactorChannelId: payload
			}
		}

		case "SET_TWO_FACTOR_SETTINGS": {
			return {
				...state,
				twoFactorSettings: payload
			}
		}

		case "SET_TWO_FACTOR_AVAILABEL_CHANNELS": {
			return {
				...state,
				twoFactorAvailabelChannels: payload
			}
		}

		case "SET_CONFIRMED_PROFILE": {
			return {
				...state,
				confirmedProfile: payload
			}
		}

		case "CLEAR_CONFIRMED_PROFILE": {
			return {
				...state,
				confirmedProfile: null
			}
		}

        default:
            return state;
    }
}

export default userReducer