const baseState = {
    userActiveTokenNumber: "",
    userTokenExpiredAfterSec: "",
    clientActiveTokenNumber: "",
    clientTokenExpiredAfterSec: "",
    timeDelta: null,
    expires_in: null
}

const ConfigReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_ALL_CONFIG_FIELDS": {
			return {
				...state,
				userActiveTokenNumber: payload.userActiveTokenNumber,
				userTokenExpiredAfterSec: payload.userTokenExpiredAfterSec,
				clientActiveTokenNumber: payload.clientActiveTokenNumber,
				clientTokenExpiredAfterSec: payload.clientTokenExpiredAfterSec,
                timeDelta: payload.preGenerationTokenWindow
			}
		}

        case "SET_EXPIRE_IN_TIME": {
            return {
                ...state,
                expires_in: payload
            }
        }

        default:
            return state;
    }
}

export default ConfigReducer