import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'
import { tokenUrl } from '../actions/helpers'
import { createClient, updateClient, getTokens } from '../actions/api';
import { setShowCreateOrEditForm, clearSelectedClient, setCreateClientMode, setShowClientsTokens, setTokensList, setSelectedItem, clearTokensList } from '../actions/localStates'

import CreateEditClientView from '../components/CreateEditClientView'
import ClientTokenContainer from './ClientTokenContainer'

class ClientContainer extends Component {

    constructor(props) {
    super(props);

        this.state = {
        }

        this.createUpdateRef = React.createRef();

        this.createUpdateClient = this.createUpdateClient.bind(this);
        this.handleShowTokens = this.handleShowTokens.bind(this);
    }

	componentDidMount() {

	}

    handleShowTokens () {
        var params = {
            "url": this.props.base.serviceUrl,
            "type": "token",
            "page": 0,
            "size": 50,
            "subjectType": "CLIENT",
            "subject": this.props.client.clientName
        }
        this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
        .then((response) => {
            this.props.actions.clearTokensList();
            this.props.actions.setTokensList(response.content);
            this.props.actions.setShowClientsTokens(true);
            console.log(response);
        })
    }

    createUpdateClient () {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

        var clientData = {
            "clientName": this.props.client.clientName,
            "fullName": this.props.client.clientFullName !== undefined && this.props.client.clientFullName.length > 0 ? this.props.client.clientFullName.replace("\\s+", " ").trim() : "",
            "roles": this.props.client.clientRoles || [],
            "password": this.props.client.clientPassword,
            "status": this.props.client.clientStatus || "ACTIVE",
            "activeTokenNumber": this.props.client.clientActiveTokenNumber,
            "tokenExpiredAfterSec": this.props.client.clientTokenExpiredAfterSec,
            "extendedData": this.props.client.clientExtendedData,
            "email": this.props.client.clientEmail,
            "createdDate": this.props.client.clientCreatedDate.length > 0 ? this.props.client.clientCreatedDate : localISOTime
        }

        this.props.base.createClientMode
        ? this.props.actions.createClient(this.props.base.serviceUrl, clientData, this.props.login.accessToken)
            .then((response) => {
                if (response.code !== undefined) {
                    alert(response.message);
                    return
                }
                alert(i18next.t("clientSuccessfullyCreatedMessage"));
                this.props.actions.setShowCreateOrEditForm(false);
                this.props.actions.setCreateClientMode(false);
                this.props.actions.setSelectedItem(null);
                this.props.actions.clearSelectedClient();
            })
        : this.props.actions.updateClient(this.props.base.serviceUrl, clientData, this.props.login.accessToken)
            .then((response) => {
                console.log(response);
                alert(i18next.t("changesSavedMessage"));
                this.props.actions.setShowCreateOrEditForm(false)
                this.props.actions.setSelectedItem(null);
                this.props.actions.clearSelectedClient();
            })
    }

	render() {
		return (
            <div>               
                {
                    this.props.client.showClientTokenView
                    ? <ClientTokenContainer />
                    : <CreateEditClientView createUpdateClient={this.createUpdateClient} forwardRef={this.createUpdateRef} />
                }
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        client : state.clientReducer,
        tokens: state.tokensReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        createClient,
        updateClient,
        setShowCreateOrEditForm,
        clearSelectedClient,
        setCreateClientMode,
        setShowClientsTokens,
        getTokens,
        setTokensList,
        setSelectedItem,
        tokenUrl,
        clearTokensList
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientContainer);