import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';

import { setToken, setExpireInTime, setIsLoggedIn, setLoggedInUserData, setAllConfigFields, setTwoFactorEnabled, clearTwoFactorEnabled,
            clearTwoFactorCode, setShowSessionEndHandler, setNeedReLogin } from "../actions/localStates"
import { login, verifyToken, getConfiguration, init2f, login2f } from "../actions/api"

import Body from '../components/Body';
import Drawer from '../components/Drawer';
import TwoFactorPopup from '../components/TwoFactorPopup'

// var hidden, visibilityChange;
const cookies = new Cookies();

class BodyContainer extends Component {
	constructor() {
		super();
		this.state = {
		  seconds: 0,
		  settedTimeValue: false,
		  token: ""
		};

        this.loginRef = React.createRef();
        this.login2f = this.login2f.bind(this);
        this.login = this.login.bind(this);

		this.timer = 0;
		this.startTimer = this.startTimer.bind(this);
		this.countDown = this.countDown.bind(this);
		this.setTime = this.setTime.bind(this);
		this.setTimeValue = this.setTimeValue.bind(this);
		// this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
	}

	componentDidMount() {
		this.activityWatcher();
		// if (typeof document.hidden !== "undefined") {
		//   hidden = "hidden";
		//   visibilityChange = "visibilitychange";
		// } else if (typeof document.msHidden !== "undefined") {
		//   hidden = "msHidden";
		//   visibilityChange = "msvisibilitychange";
		// } else if (typeof document.webkitHidden !== "undefined") {
		//   hidden = "webkitHidden";
		//   visibilityChange = "webkitvisibilitychange";
		// }

		// document.addEventListener(visibilityChange, this.handleVisibilityChange, false);

    	this.setTime();
	}

	componentWillUnmount () {
		this.setTimeValue()
	}

	activityWatcher () {
        var _this = this;
        var secondsSinceLastActivity = 0;

        var maxInactivity = (60 * this.props.base.timeToLogout);

        
        setInterval(function () {
            if (!_this.props.base.showSessionEndHandler && _this.props.login.isLoggedIn) {
                secondsSinceLastActivity += 1;
                // console.log(secondsSinceLastActivity + ' seconds since the user was last active');

                if (secondsSinceLastActivity > maxInactivity) {
                    // console.log('User has been inactive for more than ' + maxInactivity + ' seconds');
                    _this.props.actions.setShowSessionEndHandler(true);
                }
            }
        }, 1000);
        

        function activity() {
            secondsSinceLastActivity = 0;
        }

        var activityEvents = [
            'mousedown', 'mousemove', 'keydown',
            'scroll', 'touchstart'
        ];

        activityEvents.forEach(function(eventName) {
            document.addEventListener(eventName, activity, true);
        });
    }

	// handleVisibilityChange() {
	//   if (document[hidden]) {
	//     console.log(document[hidden])
	//   } else {
	//     console.log(document[hidden])
	//   }
	// }

	setTimeValue() {
		this.setState({settedTimeValue: false})
		clearInterval(this.timer);
		this.timer = 0;
	}

	setTime() {
		var timeToExpire = this.props.config.expires_in - this.props.config.timeDelta;
		this.setState({seconds: timeToExpire})
		this.setState({settedTimeValue: true})

		this.startTimer();
	}

	startTimer() {
		if (this.timer === 0) {
		  this.timer = setInterval(this.countDown, 1000);
		}
	}

	login2f () {
        var userData;
        var error = false;
        var code = {
            "twoFactorCode": this.props.login.twoFactorCode
        }
        
        
        this.props.actions.login2f(this.props.base.serviceUrl, code, this.props.login.accessToken)
        .then((response) => {
            if (response.code !== undefined) {
                this.props.actions.clearTwoFactorEnabled();
                this.props.actions.clearTwoFactorCode();
                this.props.actions.setPasswordInput("");
                error = true;
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };
            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);
        })
        .then(() => {
            if (!error) {
                this.props.actions.getConfiguration(this.props.base.serviceUrl, this.props.login.accessToken)
                .then((response) => {
                    if (response.code !== undefined) {
                        this.props.actions.setIsLoggedIn(false);
                        alert(response.message);
                        return
                    }

                    this.props.actions.setAllConfigFields(response);

                    this.props.actions.verifyToken(this.props.base.serviceUrl, this.props.login.accessToken)
                    .then((response) => {
                        if (response.expires_in !== undefined) {
                            this.props.actions.setExpireInTime(response.expires_in)
                        }
                        this.props.actions.setLoggedInUserData(userData);
                        this.props.actions.setTwoFactorEnabled(false);
                        this.props.actions.clearTwoFactorCode();
                        this.props.actions.setIsLoggedIn(true);
                        this.setTime();
                    })
                })
            }
        })
    }

	login () {
        var userData;
    	var user = {
    		"grant_type" : "password",
    		"username" : this.props.login.login,
    		"client_id" : this.props.base.clientId,
    		"password" : this.props.login.password
    	}

        var data = {
            twoFactorCode: ""
        }

        this.props.actions.setShowSessionEndHandler(false);
		this.props.actions.setNeedReLogin(false);

    	this.props.actions.login(this.props.base.serviceUrl, user)
    	.then((response) => {
            if (response.code !== undefined) {
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };
            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);

            if (userData.twof) {
                this.props.actions.init2f(this.props.base.serviceUrl, data, this.props.login.accessToken)
                .then((response) => {
                    console.log(response);
                })
                this.props.actions.setTwoFactorEnabled(true);
                return;
            }
    	})
        .then(() => {
            if (userData !== undefined && !userData.twof) {
                this.props.actions.login2f(this.props.base.serviceUrl, user, this.props.login.accessToken)
                .then((response) => {
                    if (response.code !== undefined) {
                        this.props.actions.clearTwoFactorEnabled();
                        this.props.actions.clearTwoFactorCode();
                        this.props.actions.setPasswordInput("");
                        alert(response.message);
                        return
                    }

                    function parseJwt (token) {
                        var base64Url = token.split('.')[1];
                        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        }).join(''));

                        return JSON.parse(jsonPayload);
                    };
                    this.props.actions.setToken(response.access_token);
                    userData = parseJwt(response.access_token);
                })
                .then(() => {
                    this.props.actions.getConfiguration(this.props.base.serviceUrl, this.props.login.accessToken)
                    .then((response) => {
                        if (response.code !== undefined) {
                            this.props.actions.setIsLoggedIn(false);
                            alert(response.message);
                            return
                        }

                        this.props.actions.setAllConfigFields(response);

                        this.props.actions.verifyToken(this.props.base.serviceUrl, this.props.login.accessToken)
                        .then((response) => {
                            if (response.expires_in !== undefined) {
                                this.props.actions.setExpireInTime(response.expires_in)
                            }
                            this.props.actions.setLoggedInUserData(userData);
                            this.props.actions.setIsLoggedIn(true);
                            this.setTime();
                        })
                    })
                    
                })
            }
            
        })
	}

	countDown() {
		let seconds = this.state.seconds - 1;
		this.setState({
		  seconds: seconds,
		});

		cookies.set('timestamp', Date.now());

		if (seconds <= 0) {
			clearInterval(this.timer);
			this.setTimeValue();
			this.props.actions.setShowSessionEndHandler(true);
		}
	}

	render() {
		return (
			<div className="row body_div" style={{marginLeft: "0px", marginRight: "0px", top: "60px"}}>
				<Drawer />
				<Body />
                {
                    this.props.login.twoFactorEnabled
                    ? <TwoFactorPopup loginUser2f={this.login2f} forwardRef={this.loginRef} />
                    : null
                }
				{
					this.props.base.needReLogin
					? this.login()
					: null
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        config: state.ConfigReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    setToken, setExpireInTime, setIsLoggedIn, setLoggedInUserData,
	    setAllConfigFields, setTwoFactorEnabled, clearTwoFactorEnabled,
        clearTwoFactorCode, login, verifyToken, getConfiguration, init2f, login2f,
        setShowSessionEndHandler, setNeedReLogin    
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer);