import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { getTokens, blockToken, revokeToken, unblockToken } from '../actions/api'
import { clearTokensList, setShowTokens, setShowClientTokensView, setTokensList } from '../actions/localStates'
import { formatDate, tokenUrl } from '../actions/helpers'

import ConfirmPopup from './ConfirmPopup'

class ClientsTokenListView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	statusValues: ["ACTIVE", "BLOCKED", "REVOKED"],
	    	current_token: null,
	    	current_event: null,
	    	showPopup: false,
	    	createButtonEnabled: true
	    }

	    this.keyUpHandler = this.keyUpHandler.bind(this, 'returnToUsers');

	    this.handleShowAlertMessage = this.handleShowAlertMessage.bind(this);
	    this.changeStatusWithParams = this.changeStatusWithParams.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keyup', this.keyUpHandler);
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.keyUpHandler);
	}

	handleCreateToken() {
		var enableButton, _this = this;
		this.setState({"createButtonEnabled": false});
		this.props.handleCreateToken();

		enableButton = function() {
			_this.setState({"createButtonEnabled": true});
		}

		window.setTimeout(function() {
			enableButton();
		}, 500)
	}

	handleShowAlertMessage () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });

	    var params = {
			"url": this.props.base.serviceUrl,
            "type": "token",
            "page": 0,
            "size": 50,
            "subjectType": "CLIENT",
            "subject": this.props.client.clientName
		}

		this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
		.then((response) => {
			if (response === null) {
				this.props.actions.setIsLoggedIn(false);
				return
			}
			this.props.actions.clearTokensList();
			this.props.actions.setTokensList(response.content);
		})
	}

	keyUpHandler(refName, e) {
		if (e.keyCode === 8 && e.target.tagName === 'BODY') {
			this.props.actions.clearTokensList();
			this.props.actions.setShowTokens(false);
			this.props.actions.setShowClientTokensView(false);
			document.activeElement.blur();
		}
	}

	changeStatus(token, value) {
		var currentAction;

		document.activeElement.blur();

		switch (value)
		{
		    case "ACTIVE":
		        currentAction = this.props.actions.unblockToken(this.props.base.serviceUrl, token.id, this.props.login.accessToken)
		        break;

		    case "BLOCKED":
		        currentAction = this.props.actions.blockToken(this.props.base.serviceUrl, token.id, this.props.login.accessToken)
		        break;

		    case "REVOKED":
		        currentAction = this.props.actions.revokeToken(this.props.base.serviceUrl, token.id, this.props.login.accessToken)
		        break;

		    default:
		        currentAction = this.props.actions.unblockToken(this.props.base.serviceUrl, token.id, this.props.login.accessToken)
		        break;
		}

		currentAction
		.then(() => {
        	var params = {
                "url": this.props.base.serviceUrl,
	            "type": "token",
	            "page": 0,
                "size": 50,
	            "subjectType": "CLIENT",
                "subject": this.props.client.clientName
	        }
	        this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
	        .then((response) => {
	            this.props.actions.setTokensList(response.content);
	            console.log(response);
	        })

	        if (this.state.showPopup) {
				this.setState({"showPopup": false})
			}
        })
	}

    onStatusChanged (token, e) {
		if (e.target.value === "REVOKED") {
			this.setState({"current_token": token})
			this.setState({"current_event": e.target.value})
			this.handleShowAlertMessage()
		} else {
			this.changeStatus(token, e.target.value)
		}
	}

	changeStatusWithParams () {
		this.changeStatus(this.state.current_token, this.state.current_event)
	}

	formatDate(dateInString) {
        return this.props.actions.formatDate(dateInString);
    }

    getBlobData(id) {
    	var text = this.props.tokens.tokensList[id].body;
	    
    	var clientNumber = this.props.tokens.tokensList[id].id.substr(this.props.tokens.tokensList[id].id.length - 8);
    	var name = this.props.client.clientName + "-" + clientNumber;

    	var filename = name + ".txt";
	    function download(filename, text) {
	    	if (navigator.msSaveBlob) {
		      var blobObject = new Blob([text]);
		      window.navigator.msSaveBlob(blobObject, filename);
		    } else {
		    	var element = document.createElement('a');
			    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
			    element.setAttribute('download', filename);

			    element.style.display = 'none';
			    document.body.appendChild(element);

			    element.click();

			    document.body.removeChild(element);
		    }
		};

	    download(filename, text);   
	}

	render() {
		return (
			<div className="col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
				<div style={{paddingBottom: "50px"}}>
					<button className="btn btn-outline-info" style={{marginBottom: "10px", float: "right", marginRight: "10px"}} disabled={!this.state.createButtonEnabled} onClick={this.handleCreateToken.bind(this)}>{i18next.t("createTokenLabel")}</button>
				</div>
				
				<div className="col-12 table_div" style={{paddingLeft: "0px", paddingRight: "0px", height: "100%"}}>
					<div className="row fixed_header_div">
						<div className="col-3">{i18next.t("tokenIdLabel")}</div>
						<div className="col-2">{i18next.t("notBeforeLabel")}</div>
						<div className="col-2">{i18next.t("notAfterLabel")}</div>
						<div className="col-2">{i18next.t("statusLabel")}</div>
						<div className="col-2">{i18next.t("tokenOwnerLabel")}</div>
						<div className="col-1">{i18next.t("downloadLabel")}</div>
					</div>
					<div className="fixed_div">
						{
	            			this.props.tokens.tokensList.map((token, index) => {
							    return (<div key={index} className="row" style={{marginTop: "5px"}}>
							    		<div className="col-3" style={{textAlign: "left"}}>{token.id}</div>
							    		<div className="col-2" style={{textAlign: "center"}}>{this.formatDate(token.notBefore)}</div>
							    		<div className="col-2" style={{textAlign: "center"}}>{this.formatDate(token.notAfter)}</div>
							    		<div className="col-2">
							    			{
							    				token.status === "REVOKED"
							    				? <div className="col-12 status-placeholder" disabled>
							    					{i18next.t("REVOKED")}
							    				</div>
							    				: <select type="select" className="form-control" defaultValue={token.status} style={{textAlign: "center", textAlignLast: "center"}} onChange={this.onStatusChanged.bind(this, token)}>
					                                {
					                                  this.state.statusValues.map((value, index) =>
					                                    <option key={index} selected={token.status === value ? true : false} value={value}>{i18next.t(value)}</option>)
					                                }          
					                            </select>
							    			}
			                            </div>
							    		<div className="col-2" style={{textAlign: "center"}}>{token.subject}</div>
							    		<div className="col-1" style={{textAlign: "center", cursor: "pointer"}} onClick={this.getBlobData.bind(this, index)}><i className="fa fa-download"></i></div>
							    </div>)
							})
	            		}
	            	</div>
	            	<div>
						{
							this.state.showPopup
							? <ConfirmPopup confirmChanges={this.changeStatusWithParams} closePopup={this.handleShowAlertMessage.bind(this)} />
				            : null
				        }
			        </div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        tokens: state.tokensReducer,
        client: state.clientReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getTokens,
	    clearTokensList,
	    setShowTokens,
	    setShowClientTokensView,
	    blockToken, revokeToken,
	    unblockToken,
	    setTokensList,
	    tokenUrl,
	    formatDate
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsTokenListView);