export const getUsers = (url, token) => (dispatch) => {
  return fetch(url + "/user", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return null
      }
    })
}

export const getUsersWithParams = (url, token) => (dispatch) => {
  return fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return null
      }
    })
}

export const getClientsWithParams = (url, token) => (dispatch) => {
  return fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return null
      }
    })
}

export const getClients = (url, token) => (dispatch) => {
  return fetch(url + "/client", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return null
      }
    })
}

export const getUser = (url, userName, token) => (dispatch) => {

  return fetch(url + "/user" + userName, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
       return response.json()
    })
}

export const getClient = (url, clientName, token) => (dispatch) => {
  return fetch(url + "/client" + clientName, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      console.log(response.headers);
      if(response.ok) {
        return response.json()
      }
    })
}

export const getConfiguration = (url, token) => (dispatch) => {
  return fetch(url + "/configuration", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json()
      }
    })
}

export const updateUser = (url, user, token) => (dispatch) => {
  return fetch(url + "/user/" + user.userName, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(user)
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const createUser = (url, user, token) => (dispatch) => {
  return fetch(url + "/user", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(user)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json()
      }
    })
}

export const createClient = (url, client, token) => (dispatch) => {
  return fetch(url + "/client", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(client)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json()
      }
    })
}

export const updateClient = (url, client, token) => (dispatch) => {
  return fetch(url + "/client/" + client.clientName, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(client)
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const login = (url, user) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      dataType: "json",
      body: JSON.stringify(user)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json();
      }
    })
}

export const createToken = (url, user) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      dataType: "json",
      body: JSON.stringify(user)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return null;
      }
    })
}

export const blockUser = (url, userName, token) => (dispatch) => {
  return fetch(url + "/user/" + userName + "/status", {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      }
    })
}

export const blockClient = (url, clientName, token) => (dispatch) => {
  return fetch(url + "/client/" + clientName + "/status", {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      }
    })
}

export const unblockUser = (url, userName, token) => (dispatch) => {
  return fetch(url + "/user/" + userName + "/status", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      }
    })
}

export const unblockClient = (url, clientName, token) => (dispatch) => {
  return fetch(url + "/client/" + clientName + "/status", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      }
    })
}

export const revokeUser = (url, userName, token) => (dispatch) => {
  return fetch(url + "/user/" + userName, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      }
    })
}

export const revokeClient = (url, clientName, token) => (dispatch) => {
  return fetch(url + "/client/" + clientName, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      }
    })
}

export const changeUserPassword = (url, userName, pass, token) => (dispatch) => {
  return fetch(url + "/user/" + userName + "/password", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(pass),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}

export const changeClientPassword = (url, clientName, pass, token) => (dispatch) => {
  return fetch(url + "/client/" + clientName + "/password", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(pass),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}

export const getRole = (url, roleId, token) => (dispatch) => {
  return fetch(url + "/role/" + roleId, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getRoles = (url, token) => (dispatch) => {
  return fetch(url + "/role", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const verifyToken = (url, token) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json'
      },
      body: token,
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const blockToken = (url, tokenId, token) => (dispatch) => {
  return fetch(url + "/token/" + tokenId + "/status", {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json()
      }
    })
}

export const unblockToken = (url, tokenId, token) => (dispatch) => {
  return fetch(url + "/token/" + tokenId + "/status", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json()
      }
    })
}

export const revokeToken = (url, tokenId, token) => (dispatch) => {
  return fetch(url + "/token/" + tokenId, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json()
      }
    })
}

export const getDefaults = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/config.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getVersion = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/version.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getTokens = (url, token) => (dispatch) => {
    return fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
       },
        dataType: "json"
      }).then(response => {
        if(response.ok) {
        return response.json()
      }
    });
}

export const createTokenForIssuer = (url, client, token) => (dispatch) => {
    return fetch(url + "/token/issuer", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
       },
        dataType: "json",
        body: JSON.stringify(client)
      }).then(response => {
        if(response.ok) {
        return true
      } else {
        return response.json()
      }
    });
}

export const getTokenById = (url, tokenId, token) => (dispatch) => {
  return fetch(url + "/token/" + tokenId, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
        return response.json()
    })
}

export const getAvailabelChannels = (url, token) => (dispatch) => {
  return fetch(url + "/2f", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const update2fUserInfo = (url, userName, data, token) => (dispatch) => {
  return fetch(url + "/user/" + userName, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}

export const init2f = (url, data, token) => (dispatch) => {
  return fetch(url + "/token.user.2f", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const login2f = (url, data, token) => (dispatch) => {
  return fetch(url + "/token.user", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}