import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import Popup from '../containers/Popup'
import TwoFactorPopup from '../containers/TwoFactorPopup'
import UserFormPopup from '../containers/UserFormPopup'

import { tokenUrl, toSeconds, fromSeconds } from '../actions/helpers'

import { updateUser, getRoles, changeUserPassword, getTokens, getAvailabelChannels, update2fUserInfo } from '../actions/api'
import { clearSelectedUser, setUserName, setUserFirstName, setUserSecondName, setUserStatus, setUserRole, setPhoneCode, setPhoneNumber,
		setActiveTokenNumber, setTokenExpiredAfterSec, setPosition, setDepartment, setPhone, setExtendedData, clearPhoneParams,
		setCreatedDate, setUserPassword, setUserEmail, setShowCreateOrEditUserForm, setSelectedItem, setShowUserTokensView, setTokensList,
		setTwoFactorAvailabelChannels } from '../actions/localStates'

class CreateEditUserView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	statusValues: ["ACTIVE", "BLOCKED", "REVOKED"],
	    	selectedStatus: "",
	    	selectedRoles: this.props.selectedUser.userRole || [],
	    	availabelRoles: [],
      		showPopup: false,
      		operatorsCodes: ["050", "066", "095", "099", "067", "068", "096", "097", "098", "063", "073", "093"],
      		showPassword: false,
			showConfirmPassword: false,
			userNameIsEmpty: false,
			passwordIsEmpty: false,
			confirmedPasswordNotEqual: false,
			confirmPassword: "",
			passwordMinLength: true,
			errorMessageForUser: "",
			errorMessageForPassword: "",
			errorMessageForConfirmPassword: "",
			activeTokenPeriod: ["seconds", "minutes", "hours", "days", "months", "years"],
			current_period: "seconds",
			displayed_value: 0,
			userId: this.props.selectedUser.userName || "",
			showTwoFactorPopup: false,
			showUserFormPopup: false
	    }

	    this.keyUpHandler = this.keyUpHandler.bind(this, 'returnToUsers');
	    this.keyUpEnterHandler = this.keyUpEnterHandler.bind(this, 'saveChanges');

	    this.onStatusChanged = this.onStatusChanged.bind(this);
	    this.onRoleChanged = this.onRoleChanged.bind(this);
	    this.handleShowTokens = this.handleShowTokens.bind(this);

	    this.onPhoneCodeChanged = this.onPhoneCodeChanged.bind(this);
	    this.onPhoneNumberChanged = this.onPhoneNumberChanged.bind(this);

	    this.onUserNameChanged = this.onUserNameChanged.bind(this);
	    this.onUserFirstNameChanged = this.onUserFirstNameChanged.bind(this);
	    this.onUserSecondNameChanged = this.onUserSecondNameChanged.bind(this);

	    this.onActiveTokenNumberChanged = this.onActiveTokenNumberChanged.bind(this);
		this.onTokenExpiredAfterSecChanged = this.onTokenExpiredAfterSecChanged.bind(this);
		this.onPositionChanged = this.onPositionChanged.bind(this);
		this.onDepartmentChanged = this.onDepartmentChanged.bind(this);
		this.onPhoneChanged = this.onPhoneChanged.bind(this);
		this.onExtendedDataChanged = this.onExtendedDataChanged.bind(this);
		this.onCreatedDateChanged = this.onCreatedDateChanged.bind(this);
		this.onUserPasswordChanged = this.onUserPasswordChanged.bind(this);
		this.onConfirmedPasswordChanged = this.onConfirmedPasswordChanged.bind(this);

		this.onUserEmailChanged = this.onUserEmailChanged.bind(this);

		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.handleCheckRole = this.handleCheckRole.bind(this);

		this.validateUserName = this.validateUserName.bind(this);
		this.validatePassword = this.validatePassword.bind(this);
		this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
		this.validatePass = this.validatePass.bind(this);

	    this.cancelUpdate = this.cancelUpdate.bind(this);

	    this.onActivePeriodChanged = this.onActivePeriodChanged.bind(this);
	    this.onDisplayedValueChanged = this.onDisplayedValueChanged.bind(this);
	    this.setCurrentActivePeriod = this.setCurrentActivePeriod.bind(this);

	    this.nameChanged = this.nameChanged.bind(this);

	    this.handleCloseTwoFactor = this.handleCloseTwoFactor.bind(this);
	    this.handleShowUserForm = this.handleShowUserForm.bind(this);
	}

	componentDidMount() {
		var element = document.getElementById("phone-input");
		var curr_date;
		document.addEventListener('keyup', this.keyUpHandler);
		document.addEventListener('keyup', this.keyUpEnterHandler);
		this.props.actions.getRoles(this.props.base.serviceUrl, this.props.login.accessToken)
		.then((response) => {
			var roles = [];
			response.forEach((role, index) => {
				if (role.id !== "AUTHORIZED_USER" && role.id !== "UOS_AUTHORIZED_CLIENT") {
					roles.push(role)
				}
			})
			
			this.setState({availabelRoles: roles})
		});

		if (this.props.base.createUserMode) {
			this.props.actions.setUserStatus("ACTIVE")
		}
		element.value = Number

		if (this.props.base.createUserMode) {
			this.props.actions.clearPhoneParams()
			this.setState({"displayed_value": 0})
			this.setState({"current_period": "seconds"})
		} else {
			if (this.props.selectedUser.userTokenExpiredAfterSec > 0) {
				curr_date = this.props.actions.fromSeconds(this.props.selectedUser.userTokenExpiredAfterSec)
				this.setState({"displayed_value": curr_date.value})
				this.setState({"current_period": curr_date.unit})
			}
		}
	}

	validateUserName () {
		this.props.selectedUser.userName.length > 0
			? this.setState({userNameIsEmpty: false})
			: this.setState({userNameIsEmpty: true})
	}

	validatePassword () {
		this.props.selectedUser.userPassword.length > 0
			? this.setState({passwordIsEmpty: false})
			: this.setState({passwordIsEmpty: true})
	}

	validateConfirmPassword () {
		this.state.confirmPassword === this.props.selectedUser.userPassword
		? this.setState({confirmedPasswordNotEqual: false})
		: this.setState({confirmedPasswordNotEqual: true}) 
	}

	validatePass () {
		if ((this.props.selectedUser.userPassword.length > 0) && (this.props.selectedUser.userPassword === this.state.confirmPassword)) {
			this.setState({confirmedPasswordNotEqual: false})
			return true
		} else {
			this.setState({confirmedPasswordNotEqual: true}, () => {
				this.setState({errorMessageForConfirmPassword: "needToBeFilledField"})
				})
			return false
		}
	}

	validateFields () {
		if (this.props.base.createUserMode)	{
			this.props.selectedUser.userName.length > 0
				? this.setState({userNameIsEmpty: false})
				: this.setState({userNameIsEmpty: true}, () => {
					this.setState({errorMessageForUser: "needToBeFilledField"})
					})

			this.props.selectedUser.userPassword.length > 0
				? this.setState({passwordIsEmpty: false})
				: this.setState({passwordIsEmpty: true}, () => {
					this.setState({errorMessageForPassword: "needToBeFilledField"})
					})

			if (!this.state.userNameIsEmpty && !this.state.passwordIsEmpty) {
				if ((this.props.selectedUser.userPassword.length >= 8) && (this.props.selectedUser.userPassword === this.state.confirmPassword)) {
					this.setState({confirmedPasswordNotEqual: false})
					this.props.createEditUser()
				} else {
					this.setState({confirmedPasswordNotEqual: true}, () => {
						this.setState({errorMessageForConfirmPassword: "needToBeFilledField"})
					})
				}
			}
		} else {
			this.props.createEditUser()
		}
	}

	componentWillUnmount () {
		document.removeEventListener('keyup', this.keyUpHandler);
		document.removeEventListener('keyup', this.keyUpEnterHandler);
	}

	handleCloseTwoFactor () {
		this.props.actions.getAvailabelChannels(this.props.base.serviceUrl, this.props.login.accessToken)
		.then((response) => {
			this.props.actions.setTwoFactorAvailabelChannels([{id: "disabled",title: i18next.t("disabledTitle")}].concat(response));
			console.log(response);
		})
		this.setState({
	        showTwoFactorPopup: !this.state.showTwoFactorPopup
	    });
	}

	handleShowUserForm () {
		this.setState({
	        showUserFormPopup: !this.state.showUserFormPopup
	    });
	}

	handleShowTokens () {
        var params = {
            "url": this.props.base.serviceUrl,
            "type": "token",
            "page": 0,
            "size": 10,
            "subjectType": "USER",
            "subject": this.props.selectedUser.userName
        }
        this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
        .then((response) => {
            this.props.actions.setTokensList(response.content);
            console.log(response);
        })

        this.props.actions.setShowUserTokensView(true);
    }

    hoverPass() {
        this.setState({showPassword: true})
	}

	outPass() {
	    this.setState({showPassword: false})
	}

	hoverConfirmPass() {
	    this.setState({showConfirmPassword: true})
	}

	outConfirmPass() {
	    this.setState({showConfirmPassword: false})
	}

	handleCheckRole (value, e) {
		var tempArr = this.state.selectedRoles;
		function removeItem(array, item) {
		    for (var i in array) {
		        if (array[i] === item) {
		            array.splice(i, 1);
		            break;
		        }
		    }
		}
		if (e.target.checked) {
			tempArr.push(value.id);
		} else {
			removeItem(tempArr, value.id);
		}

		this.setState({selectedRoles: tempArr});
		this.props.actions.setUserRole(tempArr);
	}

	handleChangePassword () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });
	}

	createEditUser() {
		this.validateFields()
	}

	onActivePeriodChanged(e) {
		this.setState({"current_period": e.target.value})
		this.setCurrentActivePeriod(this.props.actions.toSeconds(this.state.displayed_value, e.target.value))
	}

	onDisplayedValueChanged(e) {
		this.setState({"displayed_value": e.target.value})
		this.props.actions.toSeconds(e.target.value, this.state.current_period)
		this.setCurrentActivePeriod(this.props.actions.toSeconds(e.target.value, this.state.current_period))
	}

	setCurrentActivePeriod (value) {
		this.props.actions.setTokenExpiredAfterSec(value)
	}

	cancelUpdate () {
		this.props.actions.clearSelectedUser();
		this.props.actions.setShowCreateOrEditUserForm(false);
		this.props.actions.setSelectedItem(null);
	}

	onStatusChanged (e) {
		this.props.actions.setUserStatus(e.target.value);
	}

	onRoleChanged (value) {
		this.setState({"selectedRole": value})
	}

	onUserPasswordChanged (e) {
		this.props.actions.setUserPassword(e.target.value)
		e.target.value.length > 0 && e.target.value.length < 8
			? this.setState({passwordIsEmpty: false}, () => {
				this.setState({errorMessageForPassword: "passMinLength"})
				})
			: this.setState({passwordIsEmpty: false}, () => {
				this.setState({errorMessageForPassword: ""})
				})

		if (this.state.confirmPassword.length > 0 && (this.state.confirmPassword === e.target.value)) {
			this.setState({confirmedPasswordNotEqual: false})
			this.setState({errorMessageForConfirmPassword: ""})
		}
	}

	onConfirmedPasswordChanged (e) {
		this.setState({"confirmPassword": e.target.value})
		this.props.selectedUser.userPassword === e.target.value
		? this.setState({confirmedPasswordNotEqual: false}, () => {
			this.setState({errorMessageForConfirmPassword: ""})
			})
		: this.setState({confirmedPasswordNotEqual: true}, () => {
			this.setState({errorMessageForConfirmPassword: "passNotEqual"})
			})
	}

	nameChanged () {
		this.state.userId.length > 0
			? this.setState({userNameIsEmpty: false}, () => {
				this.setState({errorMessageForUser: ""})
				})
			: this.setState({userNameIsEmpty: true}, () => {
				this.setState({errorMessageForUser: "needToBeFilledField"})
				})
	}

	onUserNameChanged (e) {
		var replacedString = e.target.value.replace(/\s+/g, '');
		this.setState({"userId": replacedString}, () => {
			this.nameChanged()
		})
		this.props.actions.setUserName(replacedString)
	}

	onUserFirstNameChanged (e) {
		this.props.actions.setUserFirstName(e.target.value)
	}

	onUserSecondNameChanged (e) {
		this.props.actions.setUserSecondName(e.target.value)
	}

	onActiveTokenNumberChanged (e) {
		this.props.actions.setActiveTokenNumber(e.target.value)
	}

	onTokenExpiredAfterSecChanged (e) {
		this.props.actions.setTokenExpiredAfterSec(e.target.value)
	}

	onPositionChanged (e) {
		this.props.actions.setPosition(e.target.value)
	}

	onDepartmentChanged (e) {
		this.props.actions.setDepartment(e.target.value)
	}

	onPhoneCodeChanged (e) {
		this.props.actions.setPhoneCode(e.target.value)
	}

	onPhoneNumberChanged (e) {
		const re = /^[0-9+-?]+$/;

	    if (e.target.value === '' || (re.test(e.target.value) && e.target.value.length < 14)) {
	    	this.props.actions.setPhoneNumber(e.target.value)
	    }	
	}

	onPhoneChanged (e) {
	    this.props.actions.setPhone(e.target.value)	
	}

	onUserEmailChanged (e) {
		this.props.actions.setUserEmail(e.target.value)
	}

	onExtendedDataChanged (e) {
		this.props.actions.setExtendedData(e.target.value)
	}

	onCreatedDateChanged (e) {
		this.props.actions.setCreatedDate(e.target.value)
	}

	renderFields () {
		let values = [];
		let user = this.props.selectedUser.selectedUser;

		Object.getOwnPropertyNames(user).forEach(function(val, idx, array) {
			values.push(
				<div key={idx} className="input-group">
				    <label className="col-md-4 col-lg-4 col-sm-4 col-xs-4">{val}</label>
				    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
				        {user[val]}
				    </div>
				</div>
			)
		});
	    return values;
	}

	keyUpHandler(refName, e) {
		if (e.keyCode === 8 && e.target.tagName === 'BODY') {
			this.props.actions.clearSelectedUser();
    		this.props.actions.setShowCreateOrEditUserForm(false)
    		this.props.actions.setSelectedItem(null)
			document.activeElement.blur();
		}
	}

	keyUpEnterHandler(refName, e) {
	    if (e.keyCode === 13 && this.state.showUserFormPopup === false) {
	      this.createEditUser()
	    }
	}

	render() {
		let statusColor, activeClass, activeClassDiv;

		activeClass  = 'edit-view-create'

		if (this.props.base.createUserMode) {
			activeClass = 'edit-view-create'
			if (this.props.base.drawerOpen) {
			    activeClass = 'edit-view-create open'
			}
		} else {
			activeClass  = 'edit-view'
			if (this.props.base.drawerOpen) {
				activeClass = 'edit-view open'
			}
		}

		activeClassDiv  = 'topright-div'
		if (this.props.base.drawerOpen) {
		    activeClassDiv = 'topright-div open'
		}

		switch(this.props.selectedUser.userStatus) {
			case "ACTIVE":
				statusColor = "col-12 status-placeholder bg-green"
				break;
			case "BLOCKED":
				statusColor = "col-12 status-placeholder bg-grey"
				break;
			case "REVOKED":
				statusColor = "col-12 status-placeholder bg-red"
				break;

			default:
				statusColor = "col-12 status-placeholder bg-green"
				break;
		}
		
		return (
			<div>
				{
					this.props.base.createUserMode
					? null
					: <div className={activeClassDiv}>
						<button className="btn btn-outline-info" style={{marginRight: "5px"}} disabled={this.props.selectedUser.userStatus === "REVOKED" || (this.props.selectedUser.userName === undefined || this.props.selectedUser.userName.length === 0) || this.props.base.createUserMode === true} onClick={this.handleShowUserForm}>{i18next.t("questionnaire")}</button>
						<button className="btn btn-outline-info" style={{marginRight: "5px"}} disabled={this.props.selectedUser.userStatus === "REVOKED" || (this.props.selectedUser.userName === undefined || this.props.selectedUser.userName.length === 0) || this.props.base.createUserMode === true} onClick={this.handleCloseTwoFactor}>{i18next.t("twoFactor")}</button>
						<button className="btn btn-outline-info" style={{marginRight: "5px"}} disabled={this.props.selectedUser.userStatus === "REVOKED"} onClick={this.handleChangePassword}>{i18next.t("changePasswordLabel")}</button>
						<button className="btn btn-outline-info" style={{marginRight: "5px"}} disabled={this.props.selectedUser.userStatus === "REVOKED"} onClick={this.handleShowTokens}>{i18next.t("userTokensLabel")}</button>
					</div>
				}
				<div className={activeClass}>
				<div className="col-8">
					<div className="row col-12">
						<div className="col-6" style={{paddingLeft: "0px"}}>
			                <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("uniqueIdLabel")}</label>
			                <div className="col-12">
								<input className={this.state.userNameIsEmpty ? "form-control is-invalid" : "form-control"} type="text" disabled={!this.props.base.createUserMode || this.props.selectedUser.userStatus === "REVOKED"} value={this.state.userId} onChange={this.onUserNameChanged} />
							</div>
							{
								this.state.errorMessageForUser.length > 0
								? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForUser)}</span>
								: null
							}
						</div>
						<div className="col-6" style={{paddingLeft: "0px"}}>
							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("userStatusLabel")}</label>
			                <div className="col-12">
			                	<div className={statusColor}>
									<span>{i18next.t(this.props.selectedUser.userStatus)}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row col-12">
						<div className="col-6" style={{paddingLeft: "0px"}}>
							
							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("surnameLabel")}</label>
			                <div className="col-12">
								<input className="form-control" type="text" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.props.selectedUser.userSecondName} onChange={this.onUserSecondNameChanged} />
							</div>

							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("nameLabel")}</label>
			                <div className="col-12">
								<input className="form-control" type="text" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.props.selectedUser.userFirstName} onChange={this.onUserFirstNameChanged} />
							</div>

							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("departmentLabel")}</label>
			                <div className="col-12">
								<input className="form-control" type="text" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.props.selectedUser.userDepartment} onChange={this.onDepartmentChanged} />
							</div>

							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("positionLabel")}</label>
			                <div className="col-12">
								<input className="form-control" type="text" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.props.selectedUser.userPosition} onChange={this.onPositionChanged} />
							</div>

							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("emailLabel")}</label>
			                <div className="col-12">
								<input className="form-control" type="text" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.props.selectedUser.userEmail} onChange={this.onUserEmailChanged} />
							</div>

							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("phoneLabel")}</label>
							<div className="col-12">
								<input id="phone-input" className="form-control" type="text" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.props.selectedUser.phoneNumber} onChange={this.onPhoneNumberChanged} />
							</div>
						</div>
						<div className="col-6" style={{paddingRight: "30px"}}>
							<label className="col-6 col-form-label" style={{fontSize: "15px", paddingLeft: "0px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("rolesLabel")}</label>
			                <div className="col-12" style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem", "listStyle": "none", "minHeight": "114px"}}>
			                	<div className="row col-12" style={{paddingLeft: "0px", paddingTop: "5px"}}>
			                		{
			                			this.state.availabelRoles.length > 0
			                			? this.state.availabelRoles.map((role, index) => {
			                				return (<div key={index} className="form-check form-check-inline col-12" style={{marginBottom: "5px"}}>
												<input type="checkbox" disabled={this.props.selectedUser.userStatus === "REVOKED"} checked={this.props.selectedUser.userRole !== undefined && this.props.selectedUser.userRole.includes(this.state.availabelRoles[index].id) ? true : false } className="form-check-input" onChange={this.handleCheckRole.bind(this, role)} />
												<label className="form-check-label">
													{	
														this.state.availabelRoles[index].description
													}
												</label>
											</div>)
			                			})
			                			: null
			                		}
								</div>
							</div>
						</div>
					</div>
					
					{
						this.props.base.createUserMode
						? <div className="row col-12">
							<div className="col-6" style={{paddingLeft: "0px"}}>
								<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("passwordTitle")}</label>
								<div className="col-12 input-group">
	                              <input className={(this.state.errorMessageForPassword.length > 0) ? "form-control is-invalid" : "form-control"} autocomplete="new-password" value={this.props.selectedUser.userPassword} onChange={this.onUserPasswordChanged} type={this.state.showPassword ? "input" : "password"} />
	                                <div className="input-group-append">
	                                    <div className="input-group-text" onMouseOver={this.hoverPass.bind(this)} onMouseOut={this.outPass.bind(this)}><i className="fa fa-eye"></i></div>
	                                </div>
	                            </div>
	                            {
									this.state.errorMessageForPassword.length > 0
									? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForPassword)}</span>
									: null
								}					
							</div>
							<div className="col-6" style={{paddingLeft: "0px"}}>
								<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("confirmPassLabel")}</label>
								<div className="col-12 input-group">
	                              <input className={this.state.confirmedPasswordNotEqual ? "form-control is-invalid" : "form-control"} autocomplete="new-password" value={this.state.confirmPassword} onChange={this.onConfirmedPasswordChanged} type={this.state.showConfirmPassword ? "input" : "password"} />
	                                <div className="input-group-append">
	                                    <div className="input-group-text" onMouseOver={this.hoverConfirmPass.bind(this)} onMouseOut={this.outConfirmPass.bind(this)}><i className="fa fa-eye"></i></div>
	                                </div>
	                            </div>
	                            {
									this.state.errorMessageForConfirmPassword.length > 0
									? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForConfirmPassword)}</span>
									: null
								}
							</div>
						</div>
						: null
					}

					<div className="row col-12">
						<div className="col-6" style={{paddingLeft: "0px"}}>
							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("advancedInfo")}</label>
			                <div className="col-12">
								<textarea className="form-control" rows="3" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.props.selectedUser.userExtendedData} onChange={this.onExtendedDataChanged} />
							</div>
						</div>
						<div className="col-6 row" style={{paddingLeft: "0px"}}>
							<div className="col-12 row" style={{paddingLeft: "0px"}}>
								<label className="col-8 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "30px"}}>{i18next.t("activeTokensNumberTitle")}</label>
				                <div className="col-4" style={{"paddingRight": "0px"}}>
									<input className="form-control col-12" style={{marginTop: "30px"}} type="text" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.props.selectedUser.userActiveTokenNumber} onChange={this.onActiveTokenNumberChanged} />
								</div>
							</div>
							<div className="col-12 row" style={{paddingLeft: "0px"}}>
								<label className="col-5 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "10px"}}>{i18next.t("tokensExpiredAfterTitle")}</label>
				                <div className="col-3" style={{paddingLeft: "0px", "paddingRight": "0px"}}>
									<input className="form-control col-12" style={{marginTop: "10px"}} type="text" disabled={this.props.selectedUser.userStatus === "REVOKED"} value={this.state.displayed_value} onChange={this.onDisplayedValueChanged} />
								</div>
								<div className="col-4" style={{marginTop: "10px", "paddingRight": "0px"}}>
									<select style={{"paddingRight": "0px"}} disabled={this.props.selectedUser.userStatus === "REVOKED"} defaultValue={this.state.current_period} className="form-control" onChange={this.onActivePeriodChanged}>
										{
		                                  this.state.activeTokenPeriod.map((value, index) =>
		                                    <option key={index} value={value} selected={this.state.current_period === value ? true : false}>{i18next.t(value)}</option>)
		                                }
									</select>
								</div>
							</div>
						</div>	
					</div>
					<div className="row" style={{float: "right", marginRight: "45px"}}>
						{
							this.props.base.createUserMode
							? <button className="btn btn-info" style={{marginTop: "10px"}} onClick={this.createEditUser.bind(this)}>{i18next.t("createLabel")}</button>
							: <button className="btn btn-info" style={{marginTop: "10px"}} disabled={this.props.selectedUser.userStatus === "REVOKED"} onClick={this.createEditUser.bind(this)}>{i18next.t("applyChangesLabel")}</button>
						}
						&nbsp;
						<button className="btn btn-outline-info" style={{marginTop: "10px"}} onClick={this.cancelUpdate}>{i18next.t("cancelLabel")}</button>
					</div>
				</div>
				
				<div>
					{
						this.state.showPopup
						? <Popup closePopup={this.handleChangePassword.bind(this)} />
			            : null
			        }
		        </div>
		        <div>
					{
						this.state.showTwoFactorPopup
						? <TwoFactorPopup closeTwoFactorPopup={this.handleCloseTwoFactor.bind(this)} />
			            : null
			        }
		        </div>
		        <div>
					{
						this.state.showUserFormPopup
						? <UserFormPopup closeUserFormPopup={this.handleShowUserForm.bind(this)} />
			            : null
			        }
		        </div>
			</div>
		</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedUser: state.userReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    clearSelectedUser, updateUser,
	    setUserName, setUserFirstName, setUserSecondName,
	    setUserStatus, setUserRole,
		setActiveTokenNumber, setTokenExpiredAfterSec,
		setPosition, setDepartment,
		setPhone, setExtendedData,
		setCreatedDate, setUserPassword,
		setUserEmail, getRoles, setShowCreateOrEditUserForm,
		setSelectedItem, changeUserPassword,
		setShowUserTokensView, getTokens,
		setTokensList, tokenUrl, setPhoneCode,
		setPhoneNumber, clearPhoneParams,
		toSeconds, fromSeconds,
		getAvailabelChannels, update2fUserInfo,
		setTwoFactorAvailabelChannels
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditUserView);