import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login, verifyToken, getConfiguration, init2f, login2f } from '../actions/api';
import { setIsLoggedIn, setToken, setLoggedInUserData, setExpireInTime, setAllConfigFields, setTwoFactorEnabled, clearTwoFactorEnabled,
            clearTwoFactorCode, setPasswordInput } from '../actions/localStates'

import LoginView from '../components/LoginView';

class LoginContainer extends Component {

	constructor(props) {
    super(props);

        this.state = {
            token: "",
            numberOfPinInput: 0,
            inputs2f: 0
        }

        this.loginRef = React.createRef();

        this.login = this.login.bind(this);
        this.login2f = this.login2f.bind(this);
    }

	componentDidMount() {

	}

    login2f () {
        var userData;
        var error = false;
        var code = {
            "twoFactorCode": this.props.login.twoFactorCode
        }
        
        
        this.props.actions.login2f(this.props.base.serviceUrl, code, this.props.login.accessToken)
        .then((response) => {
            if (response.code !== undefined) {
                if (response.code === "AUTHCOMMON-14") {
                    var currentCount = this.state.numberOfPinInput

                    var count = this.state.inputs2f

                    if (currentCount === 0) {
                        currentCount = this.props.base.numberOfPinInput - 1;
                    } else {
                        currentCount = currentCount - 1;
                        count += 1;
                    }

                    this.setState({inputs2f: count});

                    if (this.state.inputs2f === this.props.base.numberOfPinInput - 1) {
                        this.setState({inputs2f: 0});
                        this.setState({numberOfPinInput: 0});
                        this.props.actions.clearTwoFactorEnabled();
                        this.props.actions.clearTwoFactorCode();
                        this.props.actions.setPasswordInput("");
                        error = true;
                        alert(response.message);
                        return
                    }

                    this.setState({numberOfPinInput: currentCount});
                    this.props.actions.clearTwoFactorCode();
                    error = true;
                    alert(response.message);
                    return

                } else {
                    this.props.actions.clearTwoFactorEnabled();
                    this.props.actions.clearTwoFactorCode();
                    this.props.actions.setPasswordInput("");
                    error = true;
                    alert(response.message);
                    return
                }
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };
            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);
        })
        .then(() => {
            if (!error) {
                this.props.actions.getConfiguration(this.props.base.serviceUrl, this.props.login.accessToken)
                .then((response) => {
                    if (response.code !== undefined) {
                        this.props.actions.setIsLoggedIn(false);
                        alert(response.message);
                        return
                    }

                    this.props.actions.setAllConfigFields(response);

                    this.props.actions.verifyToken(this.props.base.serviceUrl, this.props.login.accessToken)
                    .then((response) => {
                        if (response.expires_in !== undefined) {
                            this.props.actions.setExpireInTime(response.expires_in)
                        }
                        this.props.actions.setLoggedInUserData(userData);
                        this.props.actions.setTwoFactorEnabled(false);
                        this.props.actions.clearTwoFactorCode();
                        this.props.actions.setIsLoggedIn(true);
                    })
                })
            }
        })
    }

	login () {
        var userData;
    	var user = {
    		"grant_type" : "password",
    		"username" : this.props.login.login,
    		"client_id" : this.props.base.clientId,
    		"password" : this.props.login.password
    	}

        var data = {
            twoFactorCode: ""
        }

    	this.props.actions.login(this.props.base.serviceUrl, user)
    	.then((response) => {
            if (response.code !== undefined) {
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };
            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);

            if (userData.twof) {
                this.props.actions.init2f(this.props.base.serviceUrl, data, this.props.login.accessToken)
                .then((response) => {
                    console.log(response);
                })
                this.props.actions.setTwoFactorEnabled(true);
                return;
            }
    	})
        .then(() => {
            if (userData !== undefined && !userData.twof) {
                this.props.actions.login2f(this.props.base.serviceUrl, user, this.props.login.accessToken)
                .then((response) => {
                    if (response.code !== undefined) {
                        this.props.actions.clearTwoFactorEnabled();
                        this.props.actions.clearTwoFactorCode();
                        this.props.actions.setPasswordInput("");
                        alert(response.message);
                        return
                    }

                    function parseJwt (token) {
                        var base64Url = token.split('.')[1];
                        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        }).join(''));

                        return JSON.parse(jsonPayload);
                    };
                    this.props.actions.setToken(response.access_token);
                    userData = parseJwt(response.access_token);
                })
                .then(() => {
                    this.props.actions.getConfiguration(this.props.base.serviceUrl, this.props.login.accessToken)
                    .then((response) => {
                        if (response.code !== undefined) {
                            this.props.actions.setIsLoggedIn(false);
                            alert(response.message);
                            return
                        }

                        this.props.actions.setAllConfigFields(response);

                        this.props.actions.verifyToken(this.props.base.serviceUrl, this.props.login.accessToken)
                        .then((response) => {
                            if (response.expires_in !== undefined) {
                                this.props.actions.setExpireInTime(response.expires_in)
                            }
                            this.props.actions.setLoggedInUserData(userData);
                            this.props.actions.setIsLoggedIn(true);
                        })
                    })
                    
                })
            }
            
        })
	}

	render() {
		return (
			<div style={{marginLeft: "0px", marginRight: "0px", height: "100%", top: "60px"}}>
				<LoginView loginUser={this.login} loginUser2f={this.login2f} forwardRef={this.loginRef} tries={this.state.numberOfPinInput} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	login,
        setIsLoggedIn,
        verifyToken,
        setToken,
        setLoggedInUserData,
        setExpireInTime,
        getConfiguration,
        setAllConfigFields,
        init2f, setTwoFactorEnabled,
        login2f, clearTwoFactorEnabled,
        clearTwoFactorCode, setPasswordInput
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);