export const changeDrawerState = (state) => (dispatch) => {
    dispatch({
        type: 'CHANGE_DRAWER_STATE',
        payload: state
    })
}

export const changeSelectedTab = (tab) => (dispatch) => {
    dispatch({
        type: 'CHANGE_SELECTED_TAB',
        payload: tab
    })
}

export const changeSearchState = (state) => (dispatch) => {
    dispatch({
        type: 'CHANGE_SEARCH_STATE',
        payload: state
    })
}

export const setIsIEBrowser = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_IE_BROWSER',
        payload: value
    })
}

export const setExpireInTime = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXPIRE_IN_TIME',
        payload: value
    })
}

export const setUsersList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_USERS_LIST',
        payload: list
    })
}

export const setPhoneParams = (object) => (dispatch) => {
    dispatch({
        type: 'SET_PHONE_PARAMS',
        payload: object
    })
}

export const setPhoneCode = (index) => (dispatch) => {
    dispatch({
        type: 'SET_PHONE_CODE',
        payload: index
    })
}

export const clearPhoneParams = (index) => (dispatch) => {
    dispatch({
        type: 'CLEAR_PHONE_PARAMS',
        payload: null
    })
}

export const setPhoneNumber = (num) => (dispatch) => {
    dispatch({
        type: 'SET_PHONE_NUMBER',
        payload: num
    })
}

export const setClientsList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENTS_LIST',
        payload: list
    })
}

export const setSelectedUser = (user) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_USER',
        payload: user
    })
}

export const clearSelectedUser = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SELECTED_USER',
        payload: null
    })
}

export const setSelectedClient = (client) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_CLIENT',
        payload: client
    })
}

export const clearSelectedClient = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SELECTED_CLIENT',
        payload: null
    })
}

export const setAllUserFields = (user) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_USER_FIELDS',
        payload: user
    })
}

export const clearAllUserFields = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ALL_USER_FIELDS',
        payload: null
    })
}

export const setAllClientsFields = (client) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_CLIENTS_FIELDS',
        payload: client
    })
}

export const clearAllClientsFields = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ALL_CLIENTS_FIELDS',
        payload: null
    })
}

export const setUserName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_NAME',
        payload: value
    })
}

export const setUserFirstName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_FIRST_NAME',
        payload: value
    })
}

export const setUserSecondName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_SECOND_NAME',
        payload: value
    })
}

export const setUserStatus = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_STATUS',
        payload: value
    })
}

export const setUserRole = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_ROLE',
        payload: value
    })
}

export const setActiveTokenNumber = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_TOKEN_NUMBER',
        payload: value
    })
}

export const setTokenExpiredAfterSec = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_EXPIRED_AFTER_SEC',
        payload: value
    })
}

export const setPosition = (value) => (dispatch) => {
    dispatch({
        type: 'SET_POSITION',
        payload: value
    })
}

export const setDepartment = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DEPARTMENT',
        payload: value
    })
}

export const setPhone = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PHONE',
        payload: value
    })
}

export const setExtendedData = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXTENDED_DATA',
        payload: value
    })
}

export const setCreatedDate = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CREATED_DATE',
        payload: value
    })
}

export const setUserPassword = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_PASSWORD',
        payload: value
    })
}

export const setUserEmail = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_EMAIL',
        payload: value
    })
}

export const setClientName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_NAME',
        payload: value
    })
}

export const setClientFullName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_FULL_NAME',
        payload: value
    })
}

export const setClientStatus = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_STATUS',
        payload: value
    })
}

export const setClientRole = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_ROLE',
        payload: value
    })
}

export const setClientActiveTokenNumber = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_ACTIVE_TOKEN_NUMBER',
        payload: value
    })
}

export const setClientTokenExpiredAfterSecond = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_TOKEN_EXPIRED_AFTER_SEC',
        payload: value
    })
}

export const setClientExtendedData = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_EXTENDED_DATA',
        payload: value
    })
}

export const setClientCreatedDate = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_CREATED_DATE',
        payload: value
    })
}

export const setClientPassword = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_PASSWORD',
        payload: value
    })
}

export const setClientEmail = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_EMAIL',
        payload: value
    })
}

export const setCreateClientMode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CREATE_CLIENT_MODE',
        payload: value
    })
}

export const setShowCreateOrEditForm = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_CREATE_OR_EDIT_FORM',
        payload: value
    })
}

export const setDefaults = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DEFAULTS',
        payload: value
    })
}

export const setLoginInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGIN_INPUT',
        payload: value
    })
}

export const setPasswordInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PASSWORD_INPUT',
        payload: value
    })
}

export const setIsLoggedIn = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_LOGGED_IN',
        payload: value
    })
}

export const setToken = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN',
        payload: value
    })
}

export const clearToken = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN',
        payload: value
    })
}

export const setLoggedInUserData = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGGED_IN_USER_DATA',
        payload: value
    })
}

export const clearLoggedInUserData = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_LOGGED_IN_USER_DATA',
        payload: value
    })
}

export const setClientCreateMode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_CREATE_MODE',
        payload: value
    })
}

export const setShowCreateOrEditUserForm = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_CREATE_OR_EDIT_USER_FORM',
        payload: value
    })
}

export const setCreateUserMode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CREATE_USER_MODE',
        payload: value
    })
}

export const setActiveTab = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_TAB',
        payload: value
    })
}

export const setSelectedItem = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_ITEM',
        payload: value
    })
}

export const setTokensList = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKENS_LIST',
        payload: value
    })
}

export const clearTokensList = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKENS_LIST',
        payload: value
    })
}

export const setShowTokens = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_TOKENS',
        payload: value
    })
}

export const setShowClientsTokens = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_CLIENTS_TOKENS',
        payload: value
    })
}

export const setUserNameForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_NAME_FOR_SEARCH',
        payload: value
    })
}

export const clearUserNameForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_USER_NAME_FOR_SEARCH',
        payload: value
    })
}

export const setUserSurnameForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_SURNAME_FOR_SEARCH',
        payload: value
    })
}

export const clearUserSurnameForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_USER_SURNAME_FOR_SEARCH',
        payload: value
    })
}

export const setUserStatusForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_STATUS_FOR_SEARCH',
        payload: value
    })
}

export const setUserIdForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_ID_FOR_SEARCH',
        payload: value
    })
}

export const clearUserIdForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_USER_ID_FOR_SEARCH',
        payload: value
    })
}

export const setClientNameForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_NAME_FOR_SEARCH',
        payload: value
    })
}

export const clearClientNameForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_CLIENT_NAME_FOR_SEARCH',
        payload: value
    })
}

export const setClientStatusForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_STATUS_FOR_SEARCH',
        payload: value
    })
}

export const clearClientStatusForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_CLIENT_STATUS_FOR_SEARCH',
        payload: value
    })
}

export const setClientIdForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_ID_FOR_SEARCH',
        payload: value
    })
}

export const clearClientIdForSearch = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_CLIENT_ID_FOR_SEARCH',
        payload: value
    })
}

export const setALLPaginationParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_PAGINATION_PARAMS',
        payload: value
    })
}

export const setPageNumber = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PAGE_NUMBER',
        payload: value
    })
}

export const setPageSize = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PAGE_SIZE',
        payload: value
    })
}

export const setTotalElements = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOTAL_ELEMENTS',
        payload: value
    })
}

export const setTotalPages = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOTAL_PAGES',
        payload: value
    })
}

export const setALLClientsPaginationParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_CLIENTS_PAGINATION_PARAMS',
        payload: value
    })
}

export const setClientPageNumber = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_PAGE_NUMBER',
        payload: value
    })
}

export const setClientPageSize = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_PAGE_SIZE',
        payload: value
    })
}

export const setClientTotalElements = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_TOTAL_ELEMENTS',
        payload: value
    })
}

export const setClientTotalPages = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_TOTAL_PAGES',
        payload: value
    })
}

export const setALLTokePaginationParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_TOKEN_PAGINATION_PARAMS',
        payload: value
    })
}

export const setTokenPageNumber = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_PAGE_NUMBER',
        payload: value
    })
}

export const setTokenPageSize = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_PAGE_SIZE',
        payload: value
    })
}

export const setTokenTotalElements = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_TOTAL_ELEMENTS',
        payload: value
    })
}

export const setTokenTotalPages = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_TOTAL_PAGES',
        payload: value
    })
}

export const setClientPaginationParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLIENT_PAGINATION_PARAMS',
        payload: value
    })
}

export const setTokenPaginationParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_PAGINATION_PARAMS',
        payload: value
    })
}

export const setUserPaginationParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_PAGINATION_PARAMS',
        payload: value
    })
}

export const setShowUserTokensView = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_USER_TOKENS_VIEW',
        payload: value
    })
}

export const setShowClientTokensView = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_CLIENT_TOKENS_VIEW',
        payload: value
    })
}

export const setAllConfigFields = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_CONFIG_FIELDS',
        payload: value
    })
}

export const setVersion = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERSION',
        payload: data
    })
}

export const clearClientsList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CLIENTS_LIST',
        payload: null
    })
}

export const clearUsersList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_USERS_LIST',
        payload: null
    })
}

export const setResultList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_RESULT_LIST',
        payload: list
    })
}

export const clearResultList = () => (dispatch) => {
    dispatch({
        type: 'SET_RESULT_LIST',
        payload: null
    })
}

export const setTokenForSearch = (token) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_FOR_SEARCH',
        payload: token
    })
}

export const clearTokenForSearch = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN_FOR_SEARCH',
        payload: null
    })
}

export const setTwoFactorChannelId = (id) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CHANNEL_ID',
        payload: id
    })
}

export const setTwoFactorSettings = (settings) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_SETTINGS',
        payload: settings
    })
}

export const setTwoFactorAvailabelChannels = (channels) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_AVAILABEL_CHANNELS',
        payload: channels
    })
}

export const setTwoFactorEnabled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_ENABLED',
        payload: value
    })
}

export const clearTwoFactorEnabled = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_ENABLED',
        payload: null
    })
}

export const setTwoFactorCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CODE',
        payload: value
    })
}

export const clearTwoFactorCode = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_CODE',
        payload: null
    })
}


export const setAllUserFormParams = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_USER_FORM_PARAMS',
        payload: data
    })
}

export const clearAllUserFormParams = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ALL_USER_FORM_PARAMS',
        payload: null
    })
}

export const setCommonName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_COMMON_NAME',
        payload: value
    })
}

export const setCountryName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_COUNTRY_NAME',
        payload: value
    })
}

export const setGivenName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_GIVEN_NAME',
        payload: value
    })
}

export const setLocalityName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOCALITY_NAME',
        payload: value
    })
}

export const setStateOrProvinceName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_STATE_OR_PROVINCE_NAME',
        payload: value
    })
}

export const setStreetAddress = (value) => (dispatch) => {
    dispatch({
        type: 'SET_STREET_ADDRESS',
        payload: value
    })
}

export const setSurname = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SURNAME',
        payload: value
    })
}

export const setExternalUserId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXTERNAL_USER_ID',
        payload: value
    })
}

export const setTitle = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TITLE',
        payload: value
    })
}

export const setOrganizationName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ORGANIZATION_NAME',
        payload: value
    })
}

export const setOrganizationlUnitName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ORGANIZATIONAL_UNIT_NAME',
        payload: value
    })
}

export const setPostalCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_POSTAL_CODE',
        payload: value
    })
}

export const setMail = (value) => (dispatch) => {
    dispatch({
        type: 'SET_MAIL',
        payload: value
    })
}

export const setDrfou = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DRFOU',
        payload: value
    })
}

export const setEdrpou = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EDRPOU',
        payload: value
    })
}

export const setPassport = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PASSPORT',
        payload: value
    })
}

export const setUnzr = (value) => (dispatch) => {
    dispatch({
        type: 'SET_UNZR',
        payload: value
    })
}

export const setTelephoneNumber = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TELEPHONE_NUMBER',
        payload: value
    })
}

export const setSerial = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SERIAL',
        payload: value
    })
}

export const setChallengePassword = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CHALLENGE_PASSWORD',
        payload: value
    })
}

export const setRegistarCipId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_REGISTRAR_CIP_ID',
        payload: value
    })
}

export const setAllowedCertificateProfileId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALLOWED_CERTIFICATE_PROFILE_ID',
        payload: value
    })
}

export const setConfirmedProfile = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CONFIRMED_PROFILE',
        payload: value
    })
}

export const clearConfirmedProfile = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CONFIRMED_PROFILE',
        payload: null
    })
}

export const setNeedToLogout = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_TO_LOGOUT',
        payload: value
    })
}

export const setNeedReLogin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_RELOGIN',
        payload: value
    })
}

export const setShowSessionEndHandler = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_SESSION_END_HANDLER',
        payload: value
    })
}