const baseState = {
    number: 0,
    size: 20,
    totalElements: 0,
    totalPages: 0,
    clientsPaginationParamsSetted: false
}

const clientPaginationReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_ALL_CLIENTS_PAGINATION_PARAMS": {
			return {
				...state,
				number: payload.number,
			    size: payload.size,
			    totalElements: payload.totalElements,
			    totalPages: payload.totalPages
			}
		}

    	case "SET_CLIENT_PAGE_NUMBER": {
			return {
				...state,
				number: payload
			}
		}

		case "SET_CLIENT_PAGE_SIZE": {
			return {
				...state,
				size: payload
			}
		}

		case "SET_CLIENT_TOTAL_ELEMENTS": {
			return {
				...state,
				totalElements: payload
			}
		}

		case "SET_CLIENT_TOTAL_PAGES": {
			return {
				...state,
				totalPages: payload
			}
		}

		case "SET_CLIENT_PAGINATION_PARAMS": {
			return {
				...state,
				clientsPaginationParamsSetted: payload
			}
		}

        default:
            return state;
    }
}

export default clientPaginationReducer