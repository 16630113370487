import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTokens, createTokenForIssuer } from '../actions/api'
import { setTokensList, setShowTokens, clearTokensList } from '../actions/localStates'

import { tokenUrl } from '../actions/helpers'

import ClientsTokenListView from '../components/ClientsTokenListView'

class ClientTokenContainer extends Component {

	constructor(props) {
        super(props);

        this.state = {
        }

        this.createTokenForIssuerRef = React.createRef();

        this.handleCreateToken = this.handleCreateToken.bind(this);
    }

	componentDidMount() {
		this.props.actions.setShowTokens(true);
        var params = {
            "url": this.props.base.serviceUrl,
            "type": "token",
            "page": 0,
            "size": 50,
            "subjectType": "CLIENT",
            "subject": this.props.client.clientName
        }
        this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
        .then((response) => {
            this.props.actions.clearTokensList();
            this.props.actions.setTokensList(response.content);
            console.log(response);
        })
	}

	handleCreateToken () {
        var client = {
            "subjectType" : "CLIENT",
            "subjectId" : this.props.client.clientName,
            "status": "ACTIVE"
        }
        this.props.actions.createTokenForIssuer(this.props.base.serviceUrl, client, this.props.login.accessToken)
        .then((response) => {
            if (response.code !== undefined) {
                alert(response.message);
                return
            }
        })
        .then(() => {
        	var params = {
                "url": this.props.base.serviceUrl,
	            "type": "token",
	            "page": 0,
                "size": 50,
	            "subjectType": "CLIENT",
                "subject": this.props.client.clientName
	        }
	        this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
	        .then((response) => {
                this.props.actions.clearTokensList();
	            this.props.actions.setTokensList(response.content);
	            console.log(response);
	        })
        })
    }

	render() {
		return (
			<ClientsTokenListView handleCreateToken={this.handleCreateToken} forwardRef={this.createTokenForIssuerRef} />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        client: state.clientReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getTokens,
	    createTokenForIssuer,
	    setTokensList,
	    setShowTokens,
        tokenUrl,
        clearTokensList
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientTokenContainer);