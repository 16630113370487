import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateUser } from '../actions/api';
import { setCommonName, setCountryName, setGivenName, setLocalityName, setStateOrProvinceName, setStreetAddress, setSurname, setExternalUserId, setTitle, setOrganizationName,
          setOrganizationlUnitName, setPostalCode, setMail, setDrfou, setEdrpou, setPassport, setUnzr, setTelephoneNumber, setSerial, setChallengePassword, setRegistarCipId,
          setAllowedCertificateProfileId, setAllUserFormParams, clearAllUserFormParams, setConfirmedProfile, clearConfirmedProfile } from '../actions/localStates';

import i18next from 'i18next'

class UserFormPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          baseData: null
      }

      this.handleConfirm = this.handleConfirm.bind(this);

      this.handleCommonNameChanged = this.handleCommonNameChanged.bind(this);
      this.handleCountryNameChanged = this.handleCountryNameChanged.bind(this);
      this.handleGivenNameChanged = this.handleGivenNameChanged.bind(this);

      this.handleLocalityNameChanged = this.handleLocalityNameChanged.bind(this);
      this.handleStateOrProvinceNameChanged = this.handleStateOrProvinceNameChanged.bind(this);
      this.handleStreetAddressChanged = this.handleStreetAddressChanged.bind(this);

      this.handleSurnameChanged = this.handleSurnameChanged.bind(this);
      this.handleExternalUserIdChanged = this.handleExternalUserIdChanged.bind(this);
      this.handleTitleChanged = this.handleTitleChanged.bind(this);

      this.handleOrganizationNameChanged = this.handleOrganizationNameChanged.bind(this);
      this.handleOrganizationlUnitNameChanged = this.handleOrganizationlUnitNameChanged.bind(this);
      this.handlePostalCodeChanged = this.handlePostalCodeChanged.bind(this);

      this.handleMailChanged = this.handleMailChanged.bind(this);
      this.handleDrfouChanged = this.handleDrfouChanged.bind(this);
      this.handleEdrpouChanged = this.handleEdrpouChanged.bind(this);

      this.handlePassportChanged = this.handlePassportChanged.bind(this);
      this.handleUnzrChanged = this.handleUnzrChanged.bind(this);
      this.handleTelephoneNumberChanged = this.handleTelephoneNumberChanged.bind(this);

      this.handleSerialChanged = this.handleSerialChanged.bind(this);
      this.handleChallengePasswordChanged = this.handleChallengePasswordChanged.bind(this);
      this.handleRegistarCipIdChanged = this.handleRegistarCipIdChanged.bind(this);

      this.handleAllowedCertificateProfileIdChanged = this.handleAllowedCertificateProfileIdChanged.bind(this);

      this.importData = this.importData.bind(this);

      this.keyUpHandler = this.keyUpHandler.bind(this, 'returnToUsers');
  }

  handleCommonNameChanged (e) {
      var str = e.target.value.replace(/  +/g, ' '); 
      this.props.actions.setCommonName(str);
  }

  handleCountryNameChanged (e) {
      this.props.actions.setCountryName(e.target.value);
  }

  handleGivenNameChanged (e) {
      var str = e.target.value.replace(/  +/g, ' ');
      this.props.actions.setGivenName(str);
  }

  handleLocalityNameChanged (e) {
      this.props.actions.setLocalityName(e.target.value);
  }

  handleStateOrProvinceNameChanged (e) {
      this.props.actions.setStateOrProvinceName(e.target.value);
  }

  handleStreetAddressChanged (e) {
      this.props.actions.setStreetAddress(e.target.value);
  }

  handleSurnameChanged (e) {
      this.props.actions.setSurname(e.target.value);
  }

  handleExternalUserIdChanged (e) {
      this.props.actions.setExternalUserId(e.target.value);
  }

  handleTitleChanged (e) {
      this.props.actions.setTitle(e.target.value);
  }

  handleOrganizationNameChanged (e) {
      this.props.actions.setOrganizationName(e.target.value);
  }

  handleOrganizationlUnitNameChanged (e) {
      this.props.actions.setOrganizationlUnitName(e.target.value);
  }

  handlePostalCodeChanged (e) {
      this.props.actions.setPostalCode(e.target.value);
  }

  handleMailChanged (e) {
      this.props.actions.setMail(e.target.value);
  }

  handleDrfouChanged (e) {
      const re = /^[ІіА-яA-z0-9]+$/;

      if (e.target.value === '' || (re.test(e.target.value) && e.target.value.length < 11)) {
        this.props.actions.setDrfou(e.target.value);
      } else {
          if (re.test(e.target.value) && e.target.value.length < 11) {
            this.props.actions.setDrfou(e.target.value);
            return;
          } else {
            if (e.target.value.length >= 11) {
              return
            } else {
              this.props.actions.setDrfou("");
            }
          }
      }
  }

  handleEdrpouChanged (e) {
      const re = /^[0-9]+$/;

      if (e.target.value === '' || (re.test(e.target.value) && e.target.value.length < 9)) {
        this.props.actions.setEdrpou(e.target.value);
      } else {
          if (re.test(e.target.value) && e.target.value.length < 9) {
            this.props.actions.setEdrpou(e.target.value);
            return;
          } else {
            if (e.target.value.length >= 9) {
              return
            } else {
              this.props.actions.setEdrpou("");
            }
          }
      }
  }

  handlePassportChanged (e) {
      const re = /^[ІіА-я0-9]+$/;
      const reC = /^[ІіА-я]+$/;

      if (e.target.value === '' || (re.test(e.target.value) && e.target.value.length < 10)) {
        this.props.actions.setPassport(e.target.value);
      } else {
          if (reC.test(e.target.value.substr(0))) {
            if (re.test(e.target.value) && e.target.value.length < 9) {
              this.props.actions.setPassport(e.target.value);
              return;
            } else {
              if (e.target.value.length >= 9) {
                return
              } else {
                this.props.actions.setPassport("");
              }
            }
          } else {
            if (re.test(e.target.value) && e.target.value.length < 10) {
              this.props.actions.setPassport(e.target.value);
              return;
            } else {
              if (e.target.value.length >= 10) {
                return
              } else {
                this.props.actions.setPassport("");
              }
            }
          }
      }
  }

  handleUnzrChanged (e) {
      const re = /^[0-9-]+$/;
      var value;

      if (e.target.value === '' || (re.test(e.target.value) && e.target.value.length < 15)) {
        if (e.target.value.length === 8 && (e.target.value.lastIndexOf('-') === -1) && this.props.form.UNZR.lastIndexOf('-') === -1) {
          value = e.target.value + "-";
        } else {
          value = e.target.value
        }

        this.props.actions.setUnzr(value);
      } else {
          if (re.test(e.target.value) && e.target.value.length < 15) {
            this.props.actions.setUnzr(e.target.value);
            return;
          } else {
            if (e.target.value.length >= 15) {
              return
            } else {
              this.props.actions.setUnzr("");
            }
          }
      }
  }

  handleTelephoneNumberChanged (e) {
      const re = /^[0-9+-?]+$/;

      if (e.target.value === '' || (re.test(e.target.value) && e.target.value.length < 14)) {
        this.props.actions.setTelephoneNumber(e.target.value);
      }

  }

  handleSerialChanged (e) {
      this.props.actions.setSerial(e.target.value);
  }

  handleChallengePasswordChanged (e) {
      this.props.actions.setChallengePassword(e.target.value);
  }

  handleRegistarCipIdChanged (e) {
      this.props.actions.setRegistarCipId(e.target.value);
  }

  handleAllowedCertificateProfileIdChanged (e) {
      this.props.actions.setAllowedCertificateProfileId(e.target.value);
  }

  componentDidMount() {
      this.props.actions.setAllUserFormParams(this.props.user.confirmedProfile)
      document.addEventListener('keyup', this.keyUpHandler);
      this.setState({baseData: this.props.user.confirmedProfile !== null ? this.props.user.confirmedProfile : this.props.form}, () => {
        console.log(this.state.baseData);
        console.log(this.props.form)
      })
  }

  importData () {
    var str = this.props.selectedUser.userSecondName + " " + this.props.selectedUser.userFirstName
    this.props.actions.setCommonName(str);

    if (this.props.selectedUser.userSecondName !== undefined && this.props.selectedUser.userSecondName.length > 0) {
      this.props.actions.setSurname(this.props.selectedUser.userSecondName);
    }

    if (this.props.selectedUser.userFirstName !== undefined && this.props.selectedUser.userFirstName.length > 0) {
      this.props.actions.setGivenName(this.props.selectedUser.userFirstName);
    }

    if (this.props.selectedUser.userPosition !== undefined && this.props.selectedUser.userPosition.length > 0) {
      this.props.actions.setTitle(this.props.selectedUser.userPosition);
    }

    if (this.props.selectedUser.userDepartment !== undefined && this.props.selectedUser.userDepartment.length > 0) {
      this.props.actions.setOrganizationlUnitName(this.props.selectedUser.userDepartment);
    }

    if (this.props.selectedUser.userEmail !== undefined && this.props.selectedUser.userEmail.length > 0) {
      this.props.actions.setMail(this.props.selectedUser.userEmail);
    }

    if (this.props.selectedUser.phoneNumber !== undefined && this.props.selectedUser.phoneNumber.length > 0) {
      const re = /^[0-9+-?]+$/;

      if (this.props.selectedUser.phoneNumber === '' || (re.test(this.props.selectedUser.phoneNumber) && this.props.selectedUser.phoneNumber.length < 14)) {
        this.props.actions.setTelephoneNumber(this.props.selectedUser.phoneNumber);
      }
    }

    this.props.actions.setCountryName("UA");
    
  }

  componentWillUnmount () {
      this.props.actions.clearAllUserFormParams()
      this.props.actions.setAllUserFormParams(this.state.baseData)
      document.removeEventListener('keyup', this.keyUpHandler);
  }

  handleConfirm () {
      var resultData = this.state.baseData;

      for (var key in this.state.baseData) {
          if (this.props.form.hasOwnProperty(key)) {
              resultData[key] = this.props.form[key]
          }
      }

      for (var key1 in this.props.form) {
          if (!this.state.baseData.hasOwnProperty(key1)) {
              if (this.props.form[key1] !== undefined && this.props.form[key1].length > 0) {
                  resultData[key1] = this.props.form[key1]
              }
          }
      }

      var userData = {
          "userName": this.props.user.userName,
          "confirmedProfile": resultData
      }

      this.props.actions.updateUser(this.props.base.serviceUrl, userData, this.props.login.accessToken)
      .then((response) => {
          if (response.code !== undefined) {
              alert(response.message);
              this.props.closeUserFormPopup()
              return
          } else {
              this.props.actions.setConfirmedProfile(response.confirmedProfile)
              this.props.closeUserFormPopup()
              alert(i18next.t("changesSavedMessage"));
          }
      })
  }

  keyUpHandler(refName, e) {
    if (e.keyCode === 13) {
      this.handleConfirm()
    }
  }

  render() {
      return (
          <div className='popup'>
              <div className="popup_inner_form">
                  <div className="col-12">
                      <div className="row col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                        <div className="col-10" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                          <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("questionnaire")}</h4>
                        </div>
                        <div className="col-2" style={{paddingLeft: "0px", paddingRight: "0px", paddingTop: "30px", textAlign: "right"}}>
                          <i onClick={this.importData} style={{color: "#1C92C7", fontSize: "30px", cursor: "pointer"}} className="fa fa-address-card"></i>
                        </div>
                      </div>
                      <div className="popup_inner_user_form">
                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("COMMON_NAME")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.COMMON_NAME} type="text" onChange={this.handleCommonNameChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("SURNAME")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.SURNAME} type="text" onChange={this.handleSurnameChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("GIVEN_NAME")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.GIVEN_NAME} type="text" onChange={this.handleGivenNameChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("COUNTRY_NAME")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.COUNTRY_NAME} type="text" readonly />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("LOCALITY_NAME")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.LOCALITY_NAME} type="text" onChange={this.handleLocalityNameChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("STATE_OR_PROVINCE_NAME")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.STATE_OR_PROVINCE_NAME} type="text" onChange={this.handleStateOrProvinceNameChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("STREET_ADDRESS")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.STREET_ADDRESS} type="text" onChange={this.handleStreetAddressChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("EXTERNAL_USER_ID")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.EXTERNAL_USER_ID} type="text" onChange={this.handleExternalUserIdChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("TITLE")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.TITLE} type="text" onChange={this.handleTitleChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("ORGANIZATION_NAME")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.ORGANIZATION_NAME} type="text" onChange={this.handleOrganizationNameChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("ORGANIZATIONAL_UNIT_NAME")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.ORGANIZATIONAL_UNIT_NAME} type="text" onChange={this.handleOrganizationlUnitNameChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("POSTAL_CODE")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.POSTAL_CODE} type="text" onChange={this.handlePostalCodeChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("MAIL")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.MAIL} type="text" onChange={this.handleMailChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("DRFOU")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.DRFOU} type="text" onChange={this.handleDrfouChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("EDRPOU")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.EDRPOU} type="text" onChange={this.handleEdrpouChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("PASSPORT")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.PASSPORT} type="text" onChange={this.handlePassportChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("UNZR")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.UNZR} type="text" onChange={this.handleUnzrChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("TELEPHONE_NUMBER")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.TELEPHONE_NUMBER} type="text" onChange={this.handleTelephoneNumberChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("SERIAL")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.SERIAL} type="text" onChange={this.handleSerialChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("CHALLENGE_PASSWORD")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.CHALLENGE_PASSWORD} type="text" onChange={this.handleChallengePasswordChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("ALLOWED_REGISTRAR_CIP_ID")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.ALLOWED_REGISTRAR_CIP_ID} type="text" onChange={this.handleRegistarCipIdChanged} />
                            </div>
                        </div>

                        <div>
                            <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("ALLOWED_CERTIFICATE_PROFILE_ID")}</label>
                            <div className="col-12">
                                <input className="form-control" value={this.props.form.ALLOWED_CERTIFICATE_PROFILE_ID} type="text" onChange={this.handleAllowedCertificateProfileIdChanged} />
                            </div>
                        </div>
                      </div>

                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.handleConfirm}>{i18next.t("okLabel")}</button>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.props.closeUserFormPopup}>{i18next.t("cancelLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        user: state.userReducer,
        login: state.loginReducer,
        selectedUser: state.userReducer,
        form: state.userFormReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setCommonName, setCountryName, setGivenName,
        setLocalityName, setStateOrProvinceName, setStreetAddress,
        setSurname, setExternalUserId, setTitle, setOrganizationName,
        setOrganizationlUnitName, setPostalCode, setMail, setDrfou,
        setEdrpou, setPassport, setUnzr, setTelephoneNumber, setSerial,
        setChallengePassword, setRegistarCipId, setAllowedCertificateProfileId,
        setAllUserFormParams, clearAllUserFormParams, updateUser,
        setConfirmedProfile, clearConfirmedProfile
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFormPopup);