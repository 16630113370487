const baseState = {
    COMMON_NAME: "",
	COUNTRY_NAME: "UA",
	GIVEN_NAME: "",
	LOCALITY_NAME: "",
	STATE_OR_PROVINCE_NAME: "",
	STREET_ADDRESS: "",
	SURNAME: "",
	EXTERNAL_USER_ID: "",
	TITLE: "",
	ORGANIZATION_NAME: "",
	ORGANIZATIONAL_UNIT_NAME: "",
	POSTAL_CODE: "",
	MAIL: "",
	DRFOU: "",
	EDRPOU: "",
	PASSPORT: "",
	UNZR: "",
	TELEPHONE_NUMBER: "",
	SERIAL: "",
	CHALLENGE_PASSWORD: "",
	ALLOWED_REGISTRAR_CIP_ID: "",
  	ALLOWED_CERTIFICATE_PROFILE_ID: ""
}

const userFormReducer = (state = baseState, { type, payload }) => {

    switch (type) {

    	case "SET_ALL_USER_FORM_PARAMS": {
			return {
				...state,
				COMMON_NAME: payload !== null ? payload.COMMON_NAME : "",
				COUNTRY_NAME: payload !== null ? payload.COUNTRY_NAME : "",
				GIVEN_NAME: payload !== null ? payload.GIVEN_NAME : "",
				LOCALITY_NAME: payload !== null ? payload.LOCALITY_NAME : "",
				STATE_OR_PROVINCE_NAME: payload !== null ? payload.STATE_OR_PROVINCE_NAME : "",
				STREET_ADDRESS: payload !== null ? payload.STREET_ADDRESS : "",
				SURNAME: payload !== null ? payload.SURNAME : "",
				EXTERNAL_USER_ID: payload !== null ? payload.EXTERNAL_USER_ID : "",
				TITLE: payload !== null ? payload.TITLE : "",
				ORGANIZATION_NAME: payload !== null ? payload.ORGANIZATION_NAME : "",
				ORGANIZATIONAL_UNIT_NAME: payload !== null ? payload.ORGANIZATIONAL_UNIT_NAME : "",
				POSTAL_CODE: payload !== null ? payload.POSTAL_CODE : "",
				MAIL: payload !== null ? payload.MAIL : "",
				DRFOU: payload !== null ? payload.DRFOU : "",
				EDRPOU: payload !== null ? payload.EDRPOU : "",
				PASSPORT: payload !== null ? payload.PASSPORT : "",
				UNZR: payload !== null ? payload.UNZR : "",
				TELEPHONE_NUMBER: payload !== null ? payload.TELEPHONE_NUMBER : "",
				SERIAL: payload !== null ? payload.SERIAL : "",
				CHALLENGE_PASSWORD: payload !== null ? payload.CHALLENGE_PASSWORD : "",
				ALLOWED_REGISTRAR_CIP_ID: payload !== null ? payload.ALLOWED_REGISTRAR_CIP_ID : "",
			  	ALLOWED_CERTIFICATE_PROFILE_ID: payload !== null ? payload.ALLOWED_CERTIFICATE_PROFILE_ID : ""
			}
		}

    	case "SET_COMMON_NAME": {
			return {
				...state,
				COMMON_NAME: payload
			}
		}

		case "SET_COUNTRY_NAME": {
			return {
				...state,
				COUNTRY_NAME: payload
			}
		}

		case "SET_GIVEN_NAME": {
			return {
				...state,
				GIVEN_NAME: payload
			}
		}

		case "SET_LOCALITY_NAME": {
			return {
				...state,
				LOCALITY_NAME: payload
			}
		}

		case "SET_STATE_OR_PROVINCE_NAME": {
			return {
				...state,
				STATE_OR_PROVINCE_NAME: payload
			}
		}

		case "SET_STREET_ADDRESS": {
			return {
				...state,
				STREET_ADDRESS: payload
			}
		}

		case "SET_SURNAME": {
			return {
				...state,
				SURNAME: payload
			}
		}

		case "SET_EXTERNAL_USER_ID": {
			return {
				...state,
				EXTERNAL_USER_ID: payload
			}
		}

		case "SET_TITLE": {
			return {
				...state,
				TITLE: payload
			}
		}

		case "SET_ORGANIZATION_NAME": {
			return {
				...state,
				ORGANIZATION_NAME: payload
			}
		}

		case "SET_ORGANIZATIONAL_UNIT_NAME": {
			return {
				...state,
				ORGANIZATIONAL_UNIT_NAME: payload
			}
		}

		case "SET_POSTAL_CODE": {
			return {
				...state,
				POSTAL_CODE: payload
			}
		}

		case "SET_MAIL": {
			return {
				...state,
				MAIL: payload
			}
		}

		case "SET_DRFOU": {
			return {
				...state,
				DRFOU: payload
			}
		}

		case "SET_EDRPOU": {
			return {
				...state,
				EDRPOU: payload
			}
		}

		case "SET_PASSPORT": {
			return {
				...state,
				PASSPORT: payload
			}
		}

		case "SET_UNZR": {
			return {
				...state,
				UNZR: payload
			}
		}

		case "SET_TELEPHONE_NUMBER": {
			return {
				...state,
				TELEPHONE_NUMBER: payload
			}
		}

		case "SET_SERIAL": {
			return {
				...state,
				SERIAL: payload
			}
		}

		case "SET_CHALLENGE_PASSWORD": {
			return {
				...state,
				CHALLENGE_PASSWORD: payload
			}
		}

		case "SET_REGISTRAR_CIP_ID": {
			return {
				...state,
				ALLOWED_REGISTRAR_CIP_ID: payload
			}
		}

		case "SET_ALLOWED_CERTIFICATE_PROFILE_ID": {
			return {
				...state,
				ALLOWED_CERTIFICATE_PROFILE_ID: payload
			}
		}

		case "CLEAR_ALL_USER_FORM_PARAMS": {
			return {
				...state,
				COMMON_NAME: "",
				COUNTRY_NAME: "",
				GIVEN_NAME: "",
				LOCALITY_NAME: "",
				STATE_OR_PROVINCE_NAME: "",
				STREET_ADDRESS: "",
				SURNAME: "",
				EXTERNAL_USER_ID: "",
				TITLE: "",
				ORGANIZATION_NAME: "",
				ORGANIZATIONAL_UNIT_NAME: "",
				POSTAL_CODE: "",
				MAIL: "",
				DRFOU: "",
				EDRPOU: "",
				PASSPORT: "",
				UNZR: "",
				TELEPHONE_NUMBER: "",
				SERIAL: "",
				CHALLENGE_PASSWORD: "",
				ALLOWED_REGISTRAR_CIP_ID: "",
				ALLOWED_CERTIFICATE_PROFILE_ID: ""
			}
		}

        default:
            return state;
    }
}

export default userFormReducer