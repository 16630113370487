const baseState = {
    resultList: [],
    token: ""
}

const tokenSearchReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_RESULT_LIST": {
			return {
				...state,
				resultList: payload
			}
		}

		case "CLEAR_CLEAR_LIST": {
			return {
				...state,
				resultList: []
			}
		}

		case "SET_TOKEN_FOR_SEARCH": {
			return {
				...state,
				token: payload
			}
		}

		case "CLEAR_TOKEN_FOR_SEARCH": {
			return {
				...state,
				token: ""
			}
		}

        default:
            return state;
    }
}

export default tokenSearchReducer