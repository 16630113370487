const baseState = {
    tokensList: []
}

const tokensReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_TOKENS_LIST": {
			return {
				...state,
				tokensList: payload
			}
		}

		case "CLEAR_TOKENS_LIST": {
			return {
				...state,
				tokensList: []
			}
		}

        default:
            return state;
    }
}

export default tokensReducer