import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

class ConfigView extends Component {

	componentDidMount() {

	}

	render() {
		return (
			<div className="col-8">
				<div className="col-12 row" style={{paddingLeft: "0px"}}>
					<div className="col-10 row" style={{paddingLeft: "0px", paddingBottom: "10px"}}>
						<div className="col-5" style={{paddingLeft: "0px"}}>
							<label className="col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("activeTokensNumberTitle")}</label>
						</div>
						<div className="col-3" style={{paddingLeft: "0px"}}>
							<input className="form-control col-12" value={this.props.config.userActiveTokenNumber} disabled type="text" />
						</div>
					</div>
					<div className="col-10 row" style={{paddingLeft: "0px", paddingBottom: "10px"}}>
						<div className="col-5" style={{paddingLeft: "0px"}}>
							<label className="col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("userAccessInspiratinDateLabel")}</label>
						</div>
						<div className="col-3" style={{paddingLeft: "0px"}}>
							<input className="form-control col-12" value={this.props.config.userTokenExpiredAfterSec} disabled type="text" />
						</div>
					</div>
					<div className="col-10 row" style={{paddingLeft: "0px", paddingBottom: "10px"}}>
						<div className="col-5" style={{paddingLeft: "0px"}}>
							<label className="col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("activeTokensClientNumberTitle")}</label>
						</div>
						<div className="col-3" style={{paddingLeft: "0px"}}>
							<input className="form-control col-12" value={this.props.config.clientActiveTokenNumber} disabled type="text" />
						</div>
					</div>
					<div className="col-10 row" style={{paddingLeft: "0px", paddingBottom: "10px"}}>
						<div className="col-5" style={{paddingLeft: "0px"}}>
							<label className="col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("clientAccessInspirationDateLabel")}</label>
						</div>
						<div className="col-3" style={{paddingLeft: "0px"}}>
							<input className="form-control col-12" value={this.props.config.clientTokenExpiredAfterSec} disabled type="text" />
						</div>
					</div>
					<div className="col-10 row" style={{paddingLeft: "0px", paddingBottom: "10px"}}>
						<div className="col-5" style={{paddingLeft: "0px"}}>
							<label className="col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("availableAlgorithmsLabel")}</label>
						</div>
						<div className="col-3" style={{paddingLeft: "0px"}}>
							<input className="form-control col-12" value="ES256" disabled type="text" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        config: state.ConfigReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigView);