const baseState = {
    clientName: "",
    clientStatus: "",
    clientId: ""
}

const clientSearchReducer = (state = baseState, { type, payload }) => {

    switch (type) {
		case "SET_CLIENT_NAME_FOR_SEARCH": {
			return {
				...state,
				clientName: payload
			}
		}

		case "CLEAR_CLIENT_NAME_FOR_SEARCH": {
			return {
				...state,
				clientName: ""
			}
		}

		case "SET_CLIENT_STATUS_FOR_SEARCH": {
			return {
				...state,
				clientStatus: payload
			}
		}

		case "CLEAR_CLIENT_STATUS_FOR_SEARCH": {
			return {
				...state,
				clientStatus: ""
			}
		}

		case "SET_CLIENT_ID_FOR_SEARCH": {
			return {
				...state,
				clientId: payload
			}
		}

		case "CLEAR_CLIENT_ID_FOR_SEARCH": {
			return {
				...state,
				clientId: ""
			}
		}

        default:
            return state;
    }
}

export default clientSearchReducer